import { Fragment } from 'react';

import { TopUpHistory } from 'pages/user/dashboard/topUpHistory';
import { TrialRequests } from 'pages/user/dashboard/trialRequests';

export const TopUpHistoryTab = ({ userId, isAdminView = false }) => {
  return (
    <Fragment>
      <TopUpHistory userId={userId} />
      <TrialRequests {...{ userId, isAdminView }} />
    </Fragment>
  );
};
