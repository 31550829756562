import moment from 'moment';

export const createParamsState = (params = []) => {
  const _state = {};
  params.forEach(({ name, valueType }) => {
    _state[name] = valueType === 'string' ? '' : valueType === 'number' ? '' : valueType === 'timestamp' ? null : valueType === 'array' ? [] : null;
  });
  return _state;
};

export const formatParams = (paramsState = {}) => {
  const formattedParams = { ...paramsState };
  for (const key in formattedParams) {
    if (!formattedParams[key]) {
      delete formattedParams[key];
    } else if (Array.isArray(formattedParams[key])) {
      if (formattedParams[key].length) {
        formattedParams[key] = formattedParams[key].join(',');
      } else {
        delete formattedParams[key];
      }
    } else if (typeof formattedParams[key] === 'object' && formattedParams[key]._isAMomentObject) {
      if (formattedParams[key]._isValid) {
        formattedParams[key] = moment(formattedParams[key]).format('X');
      } else {
        delete formattedParams[key];
      }
    }
  }

  return formattedParams;
};

export const syntaxHighlight = (json) => {
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;'); // eslint-disable-next-line
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
    let cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return '<span class="' + cls + '">' + match + '</span>';
  });
};
