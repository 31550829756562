import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

export const NumericFormatInput = forwardRef(function NumericFormatInput(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      allowedDecimalSeparators={false}
      decimalScale={0}
    />
  );
});
