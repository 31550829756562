import { Fragment } from 'react';

import { Card, Typography, Grid, Alert, Avatar, Divider } from '@mui/material';
import moment from 'moment';
import makeBlock from 'ethereum-blockies-base64';

import { GeneralInfoSkeleton } from 'components/common/skeletons';

export const Watchlist = ({ loading = true, data = [] }) => {
  const renderWatchList = () => {
    if (data.length) {
      return data.map(({ name, id, creationDate, address }, index, { length }) => (
        <Fragment key={id + creationDate + name}>
          <Grid container justifyContent='space-between' key={id + creationDate + name}>
            <Grid flexDirection='column' sx={{ width: '40%' }}>
              <Grid container alignItems='center'>
                <Avatar alt={address} src={makeBlock(address)} sx={{ width: 21, height: 21, marginRight: 1 }} />
                <Typography variant='body1'>{address}</Typography>
              </Grid>
            </Grid>
            <Grid flexDirection='column' sx={{ width: '20%' }}>
              <Typography variant='body1'>{name || '-'}</Typography>
            </Grid>
            <Grid flexDirection='column' sx={{ width: '20%' }}>
              <Typography variant='body1'>{moment(creationDate).format('DD.MM.YY , HH:mm')}</Typography>
            </Grid>
            <Grid flexDirection='column' sx={{ width: '20%' }} />
          </Grid>
          {index < length - 1 ? <Divider sx={{ marginY: 2 }} /> : null}
        </Fragment>
      ));
    }
    return <Alert severity='info'>No Info</Alert>;
  };

  if (loading) return <GeneralInfoSkeleton />;

  return (
    <Card sx={{ marginBottom: 3, paddingTop: 3, paddingBottom: 4, paddingX: 4 }} variant='outlined'>
      <Typography sx={{ marginBottom: 1 }} variant='h4'>
        Watchlist
      </Typography>
      {data.length ? (
        <Grid container justifyContent='space-between' sx={{ marginBottom: 2 }}>
          <Grid flexDirection='column' sx={{ width: '40%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Wallet
            </Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Name
            </Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Created
            </Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }} />
        </Grid>
      ) : null}
      {renderWatchList()}
    </Card>
  );
};
