import { Fragment } from 'react';

import { MerlinUsersInformation } from 'pages/admin/merlinUsers/users';
import { ProPlusRequests } from 'pages/admin/merlinUsers/proPlusRequests';

import { usePageTitle } from 'helpers/hooks';
import { paths } from 'consts';

export const MerlinUsers = () => {
  usePageTitle(`Merlin API Panel · ${paths.appUsers.name}`);

  return (
    <Fragment>
      <MerlinUsersInformation />
      <ProPlusRequests />
      <ProPlusRequests isEnterpriseRequests />
    </Fragment>
  );
};
