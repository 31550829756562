import { forwardRef } from 'react';
import { Alert, Snackbar, Slide } from '@mui/material';

const AlertComponent = forwardRef((props, ref) => {
  return <Alert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const Notification = ({ open, autoHideDuration = 6000, onClose, message = '', type = 'success' }) => (
  <Snackbar
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    TransitionComponent={(props) => <Slide {...props} direction='right' />}
  >
    <AlertComponent onClose={onClose} severity={type} sx={{ width: '100%' }}>
      {message}
    </AlertComponent>
  </Snackbar>
);
