import ApiInstance from 'api/apiInstance';

export class UserApi extends ApiInstance {
  static login = (loginData) => UserApi.axiosPost(`${UserApi.publicPath}/login`, loginData);

  static signUp = (data, captchaValue) => UserApi.axiosPost('/api-user/self-registration', data, { 'g-recaptcha-response': captchaValue });

  static varifyAccount = (token = '') => UserApi.axiosPost('/api-user/confirm-registration', { token });

  static getBaseUserInfo = () => UserApi.axiosGet(`${UserApi.publicPath}/baseUserInfo`);

  static getAccountBalance = (isTrial = false) => UserApi.axiosGet(`${UserApi.publicPath}/accountBalance`, isTrial ? { isTrial } : null);

  static getTopUpHistory = () => UserApi.axiosGet(`${UserApi.publicPath}/topUpHistory`);

  static getCreditsUsage = (params) => UserApi.axiosGet(`${UserApi.publicPath}/usageStatistics`, params);

  static getMostUsedEndpoints = () => UserApi.axiosGet(`${UserApi.publicPath}/mostUsedEndpoints`);

  static changeApiKey = () => UserApi.axiosPost(`${UserApi.publicPath}/resetPassword`);

  static requestUnits = (usd = 0, isTrial = false) => UserApi.axiosPost(`${UserApi.publicPath}/order`, { usd, isTrial });

  static createCoinBasePayment = (data) => UserApi.axiosPost(`${UserApi.publicPath}/coinbase/createCharge`, data);

  static createStriptePayment = (data) => UserApi.axiosPost(`${UserApi.publicPath}/stripe/createCheckoutSession`, data);

  static getRegistrationOptions = () => UserApi.axiosGet(`${UserApi.apiUserPath}/userRegistrationOptions`);

  static getAccountDetails = (signal) => UserApi.axiosGet(`${UserApi.apiUserPath}/user`, null, signal);

  static updateAccountDetails = (data) => UserApi.axiosPut(`${UserApi.apiUserPath}/edit-user`, data);

  static getTopUps = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/top-up-history`, params);

  static getStripeInvoiceLink = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/invoice-link`, params);

  static getTrialRequests = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/trial-requests`, params);

  static getUnitsUsageChart = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/credits-usage`, params);

  static getUnitsUsageTable = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/units-usage`, params);

  static getEndpointStatisticChart = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/endpoints-usage`, params);

  static getEndpointsCallHistory = (params) => UserApi.axiosGet(`${UserApi.apiUserPath}/endpoints-call-history`, params);

  static getEndpointsDescription = () => UserApi.axiosGet(`${UserApi.apiUserPath}/endpoints-description`);
}
