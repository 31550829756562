import { Route, Routes, NavLink } from 'react-router-dom';
import { Typography, Box, Grid, Link, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { ProtectedRoute } from 'components/protectedRoute';
import { CheckRole } from 'components/checkRole';
import { Dashboard } from 'pages/admin/dashboard';
import { UserDetails } from 'pages/admin/userDetails';
import { MerlinUsers } from 'pages/admin/merlinUsers';
import { MerlinUserDetails } from 'pages/admin/merlinUserDetais';
import { PromoCodes } from 'pages/admin/promoCodes';
import { NotFoundCard } from 'pages/common/404';

import { ExternalLink, ApiIcon, UserIcon, CodeIcon } from 'components';
import { ResetApiKeyModal, MyApiKeyModal } from 'components/common/modals';
import { UserMenu } from 'components/common/userMenu';

import { usePanelInitialData } from 'helpers/hooks';
import { headerHeight, wrapperWidth, wrapperPaddingX, wrapperPaddingY, ADMIN_ROLES, paths } from 'consts';
import logo from 'logo-light.png';

export const AdminPanel = () => {
  const { spacing } = useTheme();
  const { resetApiKeyModalOpen, myApiKeyModalOpen } = useSelector((s) => s.bufer);

  usePanelInitialData(true);

  return (
    <ProtectedRoute>
      <CheckRole currentRoles={ADMIN_ROLES}>
        <Grid sx={{ width: '100vw', height: headerHeight, backgroundColor: 'background.header' }} container component='header' alignItems='center' justifyContent='center'>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            sx={{ maxWidth: `calc(${wrapperWidth}px + ${spacing(wrapperPaddingX * 2)})`, paddingX: wrapperPaddingX }}
          >
            <img src={logo} alt='logo' style={{ width: 190 }} />
            <Grid sx={{ marginLeft: '42px' }}>
              <NavLink to={`/${paths.admin.path}/${paths.users.path}`} className='nav-link'>
                <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
                  <ApiIcon />
                  <Typography variant='body1' sx={{ fontWeight: 500, marginLeft: 1 }} color='text.light'>
                    {paths.users.name}
                  </Typography>
                </Grid>
              </NavLink>
            </Grid>
            <Grid sx={{ marginLeft: '32px' }}>
              <NavLink to={`/${paths.admin.path}/${paths.appUsers.path}`} className='nav-link'>
                <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
                  <UserIcon />
                  <Typography variant='body1' sx={{ fontWeight: 500, marginLeft: 1 }} color='text.light'>
                    {paths.appUsers.name}
                  </Typography>
                </Grid>
              </NavLink>
            </Grid>
            <Grid sx={{ marginLeft: '32px' }}>
              <NavLink to={`/${paths.admin.path}/${paths.promoCodes.path}`} className='nav-link'>
                <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
                  <CodeIcon />
                  <Typography variant='body1' sx={{ fontWeight: 500, marginLeft: 1 }} color='text.light'>
                    {paths.promoCodes.name}
                  </Typography>
                </Grid>
              </NavLink>
            </Grid>
            <Link
              href='https://valk-1.gitbook.io/merlin-v2-api-documentation/reference/welcome'
              target='_blank'
              rel='noreferrer'
              underline='none'
              sx={{ marginLeft: 'auto', marginRight: '70px' }}
            >
              <Grid alignItems='center' container sx={{ width: 'fit-content' }}>
                <Typography variant='body1' sx={{ fontWeight: 500, marginRight: 1 }} color='text.light'>
                  API Docs
                </Typography>
                <ExternalLink />
              </Grid>
            </Link>
            <UserMenu isAdmin />
          </Grid>
        </Grid>
        <Box sx={{ minHeight: `calc(100vh - ${headerHeight}px)`, backgroundColor: 'background.content' }}>
          <Box sx={{ maxWidth: `calc(${wrapperWidth}px + ${spacing(wrapperPaddingX * 2)})`, paddingX: wrapperPaddingX, paddingY: wrapperPaddingY, margin: '0 auto' }}>
            <Routes>
              <Route path={`/${paths.users.path}`} element={<Dashboard />} />
              <Route path={`/${paths.users.path}/:userId`} element={<UserDetails />} />
              <Route path={`/${paths.appUsers.path}`} element={<MerlinUsers />} />
              <Route path={`/${paths.appUsers.path}/:userId`} element={<MerlinUserDetails />} />
              <Route path={`/${paths.promoCodes.path}`} element={<PromoCodes />} />
              <Route path={paths.any.path} element={<NotFoundCard customRedirectPath={`/${paths.admin.path}/${paths.users.path}`} />} />
            </Routes>
          </Box>
        </Box>
        <ResetApiKeyModal key={'admin-reset-api-key-modal-' + String(resetApiKeyModalOpen)} />
        <MyApiKeyModal key={'admin-my-api-key-modal' + String(myApiKeyModalOpen)} />
      </CheckRole>
    </ProtectedRoute>
  );
};
