import { useRef, useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Select } from '@mui/material';

export const CustomSelect = ({
  containerWidth = 'calc(25% - 15px)',
  marginBottom = 0,
  disabled,
  value,
  onChange,
  showPlaceholder,
  children,
  placeholder = '',
  name = '',
  multiple = false,
  color,
  otherSelectProps = {},
}) => {
  const {
    palette: {
      text: { primary: black, linearGrey },
    },
  } = useTheme();
  const componentRef = useRef(null);

  useEffect(() => {
    if (showPlaceholder) {
      try {
        const text = document.createTextNode(placeholder);
        const placeholderSpan = componentRef.current.children[0].children[0];
        placeholderSpan.style.color = linearGrey;
        placeholderSpan.appendChild(text);
      } catch (error) {
        console.log(error);
      }
    }
  }, [showPlaceholder, placeholder, linearGrey]);

  return (
    <Grid sx={{ position: 'relative', width: containerWidth, marginBottom }}>
      <Select
        ref={componentRef}
        fullWidth
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        multiple={multiple}
        IconComponent={ExpandMoreRoundedIcon}
        sx={{
          fontSize: 12,
          height: 36,
          '.MuiSvgIcon-root': {
            top: 10,
          },
          '.MuiSelect-select': {
            paddingTop: '9px !important',
            paddingBottom: '9px !important',
            color: color || black,
          },
        }}
        {...otherSelectProps}
      >
        {children}
      </Select>
    </Grid>
  );
};
