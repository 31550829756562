import { Fragment, useMemo, useState } from 'react';
import { Card, Typography, Table, TableBody, TableCell, TableHead, TableRow, Grid, Box, Alert, Pagination } from '@mui/material';
import moment from 'moment';

import { TableSkeleton } from 'components/common/skeletons';
import { numberWithCommas } from 'helpers';
import { pageSize as portion } from 'pages/user/dashboard/const';

export const UnitsUsageTable = ({ data = [], loading = true, error = '', from, to }) => {
  const [page, setPage] = useState(0);
  const pagesCount = useMemo(() => Math.ceil(data.length / portion), [data]);

  const emptyRows = Math.max(0, (1 + page) * portion - data.length);

  const onChangePage = (_, nextPage) => setPage(nextPage - 1);

  const PageContent = useMemo(() => {
    const startIndex = page * portion;
    const endIndex = startIndex + portion;

    return data.slice(startIndex, endIndex).map(({ apiCalls, cost, day, trialUnits, units }, index) => (
      <TableRow sx={{ height: 53 }} key={'row-' + index + Math.random()}>
        <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
          <Typography variant='body1' color='text.dark'>
            {moment(day).format('DD.MM.YYYY, dddd')}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.linearGrey'>
            {numberWithCommas(units) || '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.linearGrey'>
            {numberWithCommas(trialUnits) || '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.linearGrey'>
            {numberWithCommas(apiCalls) || '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1'>${+cost.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>
    ));
  }, [data, page]);

  return (
    <Card sx={{ paddingY: 4 }} variant='outlined'>
      <Typography variant='h4' pl={4}>
        Units Usage per day {!error && !isNaN(from) && !isNaN(to) ? `(${moment(from).format('DD.MM.YY')} - ${moment(to).format('DD.MM.YY')})` : ''}
      </Typography>
      {loading ? (
        <TableSkeleton />
      ) : error ? (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : data.length ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4 }}>
                  <Typography variant='caption' color='text.secondary'>
                    DATE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    UNITS USED
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    TRIAL UNITS USED
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    API CALLS
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    COST ($)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 540 }}>
              {PageContent}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 54 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginBottom: 3 }} />
          <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
            <Pagination shape='rounded' count={pagesCount} onChange={onChangePage} size='small' color='primary' />
          </Grid>
        </Fragment>
      ) : (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='info'>No Info</Alert>
        </Box>
      )}
    </Card>
  );
};
