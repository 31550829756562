import { useState, Fragment } from 'react';
import { Dialog, Grow, Alert, Grid, Typography, Card } from '@mui/material';
import { useDispatch } from 'react-redux';

import { ButtonComponent } from 'components/ui';
import { UserApi } from 'api';
import { incrementKey, trialRequestsKey } from 'store/slices/updateObserver';

export const RequestTrialUnitsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const onSendRequest = async () => {
    setWait(true);
    const res = await UserApi.requestUnits(0, true)
      .catch(setErrroMessage)
      .finally(() => setWait(false));
    if (res) {
      setSuccess(true);
      dispatch(incrementKey(trialRequestsKey));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, paddingX: 4, paddingTop: 4, paddingBottom: success || errorMessage ? 4 : 5 }}>
        {errorMessage ? (
          <Grow in={!!errorMessage}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Grow>
        ) : success ? (
          <Grow in={success}>
            <Alert severity='success'>Request successfully sent.</Alert>
          </Grow>
        ) : (
          <Fragment>
            <Typography mb='12px' variant='h4'>
              Request Trial Units
            </Typography>
            <Typography mb='20px' variant='body2' component='p' color='text.secondary'>
              Should you need more data to asses the value of the Merlin API, please send this request for additional trial units. Our team will pick up your request in no time!
            </Typography>
            <Grid container justifyContent='space-between'>
              <ButtonComponent width={76} color='cancelBtn' onClick={onClose} text='CANCEL' />
              <ButtonComponent width={184} onClick={onSendRequest} disabled={wait} text='REQUEST UNITS' />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
