import { useState, Fragment } from 'react';
import { Card, Tabs, Tab, Grid } from '@mui/material';

import { TopUpHistoryTab } from 'pages/user/dashboard/tabsContent/topUpHistoryTab';
import { UnitsUsageTab } from 'pages/user/dashboard/tabsContent/unitsUsageTab';
import { EnpointStatisticTab } from 'pages/user/dashboard/tabsContent/endpointsStatisticTab';
import { EndpointsCallHistoryTab } from 'pages/user/dashboard/tabsContent/endpointsCallHistoryTab';
import { PricingTab } from 'pages/user/dashboard/tabsContent/pricingTab';
import { Coins, Wallet, ChartPie, History } from 'components/ui';

export const tabStyles = { textTransform: 'none', fontWeight: 400, minHeight: 58, paddingTop: '22px' };

export const TabsCard = ({ currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading, userId = '', isAdminView = false }) => {
  const [tab, setTab] = useState(0);

  const onChangeTab = (_, index) => setTab(index);

  return (
    <Fragment>
      <Card variant='outlined' sx={{ height: 60, marginBottom: 5, paddingX: 4 }}>
        <Tabs value={tab} onChange={onChangeTab} aria-label='nav-tabs' variant='scrollable' scrollButtons={false}>
          <Tab
            sx={tabStyles}
            label={
              <Grid alignItems='center' container>
                <Coins isActive={!tab} />
                Units Usage
              </Grid>
            }
            id='tab-0'
            aria-controls='tabpanel-0'
          />
          <Tab
            sx={tabStyles}
            label={
              <Grid alignItems='center' container>
                <Wallet isActive={tab === 1} />
                Top Up History
              </Grid>
            }
            id='tab-1'
            aria-controls='tabpanel-1'
          />
          <Tab
            sx={tabStyles}
            label={
              <Grid alignItems='center' container>
                <ChartPie isActive={tab === 2} />
                Endpoints Stats
              </Grid>
            }
            id='tab-2'
            aria-controls='tabpanel-2'
          />
          <Tab
            sx={tabStyles}
            label={
              <Grid alignItems='center' container>
                <History isActive={tab === 3} />
                Endpoints Call History
              </Grid>
            }
            id='tab-3'
            aria-controls='tabpanel-3'
          />
          <Tab
            sx={tabStyles}
            label={
              <Grid alignItems='center' container>
                <ChartPie isActive={tab === 4} />
                Pricing
              </Grid>
            }
            id='tab-4'
            aria-controls='tabpanel-4'
          />
        </Tabs>
      </Card>
      <div role='tabpanel' hidden={!!tab} id='tab-0' aria-labelledby='tabpanel-0'>
        <UnitsUsageTab userId={userId} />
      </div>
      <div role='tabpanel' hidden={tab !== 1} id='tab-1' aria-labelledby='tabpanel-1'>
        <TopUpHistoryTab {...{ userId, isAdminView }} />
      </div>
      <div role='tabpanel' hidden={tab !== 2} id='tab-2' aria-labelledby='tabpanel-2'>
        <EnpointStatisticTab {...{ currentUnitsPerDollar, currentUnitsPerDollarError, userId }} />
      </div>
      <div role='tabpanel' hidden={tab !== 3} id='tab-3' aria-labelledby='tabpanel-3'>
        <EndpointsCallHistoryTab userId={userId} />
      </div>
      <div role='tabpanel' hidden={tab !== 4} id='tab-4' aria-labelledby='tabpanel-4'>
        <PricingTab {...{ currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading, userId }} />
      </div>
    </Fragment>
  );
};
