import { Fragment, useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography, Grid, Box, Alert } from '@mui/material';
import { useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Divider } from '@mui/material';
import axios from 'axios';

import { PageSkeleton } from 'pages/user/liveApiTest/skeletons';
import { RequestStepper } from 'pages/user/liveApiTest/stepper';
import { ParamsBlock } from 'pages/user/liveApiTest/paramsBlock';
import { ResponseBlock } from 'pages/user/liveApiTest/responseBlock';
import { RouteRow } from 'pages/user/liveApiTest/routeRow';
import { ButtonComponent } from 'components/ui';
import { CustomSelect } from 'components/ui/selectPlaceholder/customSelect';
import { Code } from 'components/icons';
import { usePageTitle } from 'helpers/hooks';
import { UserApi } from 'api';
import { numberWithCommas } from 'helpers';
import { createParamsState, formatParams } from 'pages/user/liveApiTest/helpers';
import { paths } from 'consts';

export const LiveApiTest = () => {
  usePageTitle(`Merlin API Panel · ${paths.liveApiTest.name}`);
  const {
    palette: {
      text: { linearGrey, light },
    },
  } = useTheme();
  //initialData
  const [
    {
      loading: endpointsLoading,
      error: endpointsError,
      data: { groupsList, prices },
    },
    setEndpointsDescription,
  ] = useState({
    loading: true,
    error: '',
    data: { groupsList: [], prices: [] },
  });
  const { loading: userInfoLoading, unitsLimit, error: userInfoError, accessKey: Authorization } = useSelector((s) => s.userBaseInfo);
  //balance state to show dynamically
  const [{ accountBalance, accountBalanceTrial, loading: balanceLoading, error: balanceError }, setAccountState] = useState({
    accountBalance: 0,
    accountBalanceTrial: 0,
    loading: true,
    error: '',
  });
  //endpoint states
  const [endpointType, setEndpointType] = useState('');
  const [endpoint, setEndpoint] = useState('');
  //params state
  const [paramsState, setParamsState] = useState({});
  //response states
  const [response, setResponse] = useState(null);
  const [responseLoading, setResponseLoading] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [downloaded, setDownloaded] = useState(false);

  const currentRequestRef = useRef(null);

  const onChangeEndpointType = (event) => {
    setEndpointType(event.target.value);
    //reset all next fields
    setEndpoint('');
    setParamsState({});
    setResponse(null);
    setResponseLoading(false);
    setResponseError('');
    setDownloaded(false);
  };

  const onChangeEndpoint = (event) => {
    const endpointIndex = event.target.value;
    setEndpoint(endpointIndex);
    setParamsState(createParamsState(prices[endpointType].list[endpointIndex].params));
    //reset all next fields here - response / downloaded
    setResponse(null);
    setResponseLoading(false);
    setResponseError('');
    setDownloaded(false);
  };

  //params onChange
  const onChangeField = (event) => {
    const { name, value } = event.target;
    setParamsState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeDateField = (name) => (momentValue) => {
    setParamsState((prev) => ({ ...prev, [name]: momentValue }));
  };

  const renderEndpointTypes = () =>
    groupsList.map((el, index) => (
      <MenuItem value={index} key={el + index}>
        {el}
      </MenuItem>
    ));

  const renderEndpointsByType = (_endpointType) => {
    if (prices[_endpointType] && prices[_endpointType].list) {
      return prices[_endpointType].list.map(({ endpoint }, index) => (
        <MenuItem value={index} key={endpoint + index}>
          {endpoint || '-'}
        </MenuItem>
      ));
    }
    return null;
  };

  const onSendRequest = () => {
    if (endpointSelected) {
      //set current request signal
      currentRequestRef.current = new AbortController();
      //reset last states / set loadind
      setResponseLoading(true);
      setResponseError('');
      setDownloaded(false);
      axios
        .get(selectedEdpoint.routeExecutable2, { headers: { Authorization }, params: formatParams(paramsState), signal: currentRequestRef?.current?.signal })
        .then((res) => {
          setResponse(res.data);
          getBalance();
        })
        .catch((e) => {
          setResponseError(e.response?.data && typeof e.response?.data === 'string' ? e.response?.data : e.message);
        })
        .finally(() => {
          setResponseLoading(false);
          currentRequestRef.current = null;
        });
    }
  };

  const onCancelRequest = () => {
    if (currentRequestRef && currentRequestRef.current) {
      try {
        currentRequestRef.current.abort();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getBalance = () => {
    Promise.all([UserApi.getAccountBalance(), UserApi.getAccountBalance(true)])
      .then(([accountBalance, accountBalanceTrial]) => setAccountState({ accountBalance, accountBalanceTrial, loading: false, error: '' }))
      .catch((error) => setAccountState({ accountBalance: 0, loading: false, error }));
  };

  //initial effect to get needed data and balance
  useEffect(() => {
    UserApi.getEndpointsDescription()
      .then((res) => {
        setEndpointsDescription({
          loading: false,
          error: '',
          data: {
            prices: res,
            groupsList: res.map((el) => el.name),
          },
        });
      })
      .catch((e) => {
        setEndpointsDescription({ loading: false, error: e, data: { prices: [], groupsList: [] } });
      });
    getBalance();
  }, []);

  const loading = endpointsLoading || userInfoLoading;
  const error = endpointsError || userInfoError;

  const endpointTypeSelected = typeof endpointType === 'number';
  const endpointSelected = typeof endpoint === 'number';

  const selectedEdpoint = useMemo(() => (endpointSelected ? prices[endpointType]?.list[endpoint] : {}), [endpointSelected, endpoint, endpointType, prices]);
  const paramsSet = useMemo(
    () => (endpointSelected && selectedEdpoint.params && Array.isArray(selectedEdpoint.params) ? !selectedEdpoint.params.some((p) => p.isRequired && !paramsState[p.name]) : false),
    [endpointSelected, selectedEdpoint.params, paramsState]
  );
  const activeStep = useMemo(
    () => (downloaded ? 5 : response ? 4 : paramsSet ? 3 : endpointSelected ? 2 : endpointTypeSelected ? 1 : 0),
    [downloaded, response, paramsSet, endpointSelected, endpointTypeSelected]
  );

  return (
    <Fragment>
      {loading ? (
        <PageSkeleton />
      ) : error ? (
        <Card variant='outlined'>
          <Box sx={{ padding: 4 }}>
            <Alert severity='error' sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Box>
        </Card>
      ) : (
        <Fragment key='content'>
          <Card variant='outlined' sx={{ marginBottom: 5, overflow: 'visible' }}>
            <Box sx={{ padding: 4 }}>
              <Typography variant='h4' mb='8px'>
                Account Balance
              </Typography>
              <Grid container alignItems='flex-end'>
                {balanceLoading ? (
                  <Typography variant='h1'>
                    <Skeleton sx={{ display: 'inline-block' }} width={200} variant='text' />
                  </Typography>
                ) : balanceError ? (
                  <Alert severity='error' sx={{ width: '100%' }}>
                    {balanceError}
                  </Alert>
                ) : (
                  <Fragment>
                    <Typography color={accountBalance <= unitsLimit ? 'error.main' : undefined} variant='h1' sx={{ marginRight: 1 }}>
                      {numberWithCommas(accountBalance) || 0}
                    </Typography>
                    <Typography variant='body1' sx={{ marginBottom: 1 }}>
                      units {accountBalanceTrial ? `(including ${accountBalanceTrial} trial)` : null}
                    </Typography>
                  </Fragment>
                )}
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: 4, paddingBottom: 5.5 }}>
              <Typography variant='h4' mb='20px'>
                Create API Endpoint Call
              </Typography>
              <RequestStepper activeStep={activeStep} />
              <Typography variant='h4' mb='20px'>
                Select Endpoint
              </Typography>
              <Grid container alignItems='center' justifyContent='space-between'>
                <CustomSelect
                  {...{
                    disabled: !groupsList.length || responseLoading,
                    value: endpointType,
                    onChange: onChangeEndpointType,
                    showPlaceholder: typeof endpointType !== 'number',
                    placeholder: 'Select Type',
                    name: 'type',
                  }}
                >
                  {renderEndpointTypes()}
                </CustomSelect>
                {endpointTypeSelected ? (
                  <CustomSelect
                    {...{
                      disabled: responseLoading,
                      value: endpoint,
                      onChange: onChangeEndpoint,
                      showPlaceholder: typeof endpoint !== 'number',
                      placeholder: 'Select Endpoint',
                      name: 'endpoint',
                    }}
                  >
                    {renderEndpointsByType(endpointType)}
                  </CustomSelect>
                ) : null}
                <Box sx={{ width: 'calc(25% - 15px)' }} />
                <Box sx={{ width: 'calc(25% - 15px)' }} />
              </Grid>
              {/* params block  */}
              {endpointSelected ? (
                <Fragment>
                  <Alert severity='info' sx={{ marginY: 3 }}>
                    {selectedEdpoint.description}
                  </Alert>
                  <ParamsBlock {...{ paramsState, onChangeField, onChangeDateField, params: selectedEdpoint.params, disableFields: responseLoading }} />
                </Fragment>
              ) : null}
              {/* description */}
              {paramsSet ? (
                <Fragment key='endpoint-description'>
                  <Typography variant='h4' mt={selectedEdpoint.params.length ? '0' : '20px'} mb='20px'>
                    Info
                  </Typography>
                  <Typography color='text.secondary' mb={1}>
                    Endpoint:{' '}
                    <Typography color='text.dark' component='span'>
                      {selectedEdpoint.endpoint}
                    </Typography>
                  </Typography>
                  <RouteRow route={selectedEdpoint.route} address={paramsState.address} />
                  <Typography color='text.secondary' mb={1}>
                    Units:{' '}
                    <Typography color='text.dark' component='span'>
                      {selectedEdpoint.credits}
                    </Typography>
                  </Typography>
                  <Typography color='text.secondary' mb='20px'>
                    Cost:{' '}
                    <Typography color='text.dark' component='span'>
                      ${selectedEdpoint.cost}
                    </Typography>
                  </Typography>
                  <ButtonComponent
                    disabled={!paramsSet || responseLoading}
                    text='Send Request'
                    endIcon={<Code color={!paramsSet || responseLoading ? linearGrey : light} />}
                    onClick={onSendRequest}
                    marginRight='20px'
                  />
                  {responseLoading ? <ButtonComponent width={90} variant='outlined' color='secondary' text='Cancel' onClick={onCancelRequest} /> : null}
                </Fragment>
              ) : null}
            </Box>
          </Card>
          {/* table  */}
          <ResponseBlock {...{ response, responseError, responseLoading, setDownloaded, endpoint: prices[endpointType]?.list[endpoint]?.endpoint, paramsState }} />
        </Fragment>
      )}
    </Fragment>
  );
};
