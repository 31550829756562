import { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography, Table, TableBody, TableCell, TableHead, TableRow, Pagination, Grid, Box, CircularProgress, Alert, TableSortLabel, Tooltip } from '@mui/material';
import moment from 'moment';

import { CrossIcon, FileIcon } from 'components';
import { TableSkeleton } from 'components/common/skeletons';
import { UserApi, AdminApi } from 'api';
import { usePaginationState } from 'helpers/hooks';
import { numberWithCommas } from 'helpers';

import { pageSize, dateColWidth, typeColWidth, fixedColWidth } from 'pages/user/dashboard/const';
import { topUpHistoryKey } from 'store/slices/updateObserver';

export const TopUpHistory = ({ userId }) => {
  const observer = useSelector((s) => s.updateObserver[topUpHistoryKey]);
  const { data, error, loading, totalPages, page, sortBy, sortOrder, onChangePage, onChangeSort } = usePaginationState(
    userId ? AdminApi.getTopUps : UserApi.getTopUps,
    'date',
    'DESC',
    pageSize,
    observer,
    userId
  );

  const emptyRows = pageSize - data.length;

  const PageContent = useMemo(() => {
    return data.map((payment, index) => <TopUpItem {...{ payment, key: 'row-' + index + Math.random(), userId }} />);
  }, [data, userId]);

  return (
    <Card sx={{ paddingY: 4, marginBottom: 5 }} variant='outlined'>
      <Typography variant='h4' pl={4}>
        Top Up History
      </Typography>
      {loading ? (
        <TableSkeleton />
      ) : error ? (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : data.length ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4, width: dateColWidth }}>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'date'} onClick={onChangeSort('date')}>
                    <Typography variant='caption' color='text.secondary'>
                      DATE
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: typeColWidth }}>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'type'} onClick={onChangeSort('type')}>
                    <Typography variant='caption' color='text.secondary'>
                      TYPE
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    UNITS
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    UNITS AFTER TOP UP
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: fixedColWidth }}>
                  <Typography variant='caption' color='text.secondary'>
                    COST ($)
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: fixedColWidth }}>
                  <Typography variant='caption' color='text.secondary'>
                    INVOICE
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 540 }}>
              {PageContent}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 54 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginBottom: 3 }} />
          <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
            <Pagination shape='rounded' page={page} count={totalPages} onChange={onChangePage} size='small' color='primary' />
          </Grid>
        </Fragment>
      ) : (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='info'>No Info</Alert>
        </Box>
      )}
    </Card>
  );
};

const TopUpItem = ({ payment: { date, type, paidUsd, unitsAdded, unitsAfterTopUp, paymentCode }, userId = '' }) => {
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(false);

  const getInvoiceLink = () => {
    setWait(true);
    (userId ? AdminApi.getUserStripeInvoiceLink({ paymentCode, userId }) : UserApi.getStripeInvoiceLink({ paymentCode }))
      .then((link) => {
        const a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        a.rel = 'noreferrer';
        a.click();
      })
      .catch(() => setError(true))
      .finally(() => setWait(false));
  };
  return (
    <TableRow sx={{ height: 53 }}>
      <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
        <Typography variant='body1' color='text.dark'>
          {moment(date).format('DD.MM.YYYY hh:mm A')}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.dark'>
          {type}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.green'>
          +{numberWithCommas(unitsAdded)}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1'>{numberWithCommas(unitsAfterTopUp) || '-'}</Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.linearGrey'>
          {paidUsd ? `$${paidUsd}` : '-'}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        {wait ? (
          <CircularProgress size={14} />
        ) : error ? (
          <Grid container alignItems='center'>
            <CrossIcon size={14} />
            <Typography sx={{ marginLeft: '5px' }} variant='body1' color='text.red'>
              Error
            </Typography>
          </Grid>
        ) : paymentCode ? (
          <Tooltip
            title={
              <Typography variant='body2' color='text.dark'>
                Download Invoice
              </Typography>
            }
            arrow
            placement='top'
          >
            <Grid container alignItems='center' onClick={getInvoiceLink} sx={{ cursor: 'pointer' }}>
              <FileIcon />
              <Typography sx={{ marginLeft: '5px' }} variant='body1' color='primary.main'>
                Invoice
              </Typography>
            </Grid>
          </Tooltip>
        ) : (
          <Typography variant='body1' color='text.dark'>
            -
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};
