import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { paths } from 'consts';

export const CheckRole = ({ children, currentRoles = [] }) => {
  const { role } = useSelector((s) => s.user);

  if (currentRoles.includes(role)) return children;

  return <Navigate to={paths.empty.path} />;
};
