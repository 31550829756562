import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessKey: '',
  email: '',
  minPurchasedUnits: 0,
  unitsLimit: 1000,
  userId: '',
  isTester: null,
  loading: true,
  error: '',
};

export const userBaseInfoSlice = createSlice({
  name: 'userBaseInfo',
  initialState,
  reducers: {
    setBaseInfo: (state, { payload: { accessKey, email, minPurchasedUnits, userId, isTester, unitsLimit } }) => {
      state.accessKey = accessKey;
      state.email = email;
      state.minPurchasedUnits = minPurchasedUnits;
      state.userId = userId;
      state.isTester = isTester;
      state.unitsLimit = unitsLimit;
      state.loading = false;
      state.error = '';
    },
    setAccessKey: (state, { payload }) => {
      state.accessKey = payload;
    },
    setUnitsLimit: (state, { payload }) => {
      state.unitsLimit = payload;
    },
    setBaseInfoError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetBaseInfo: () => initialState,
  },
});

export const { setBaseInfo, setAccessKey, setUnitsLimit, setBaseInfoError, resetBaseInfo } = userBaseInfoSlice.actions;

export default userBaseInfoSlice.reducer;
