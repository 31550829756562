import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    prices: [],
    groupsList: [],
    pricesUngroupedList: {},
  },
  loading: true,
  error: '',
};

export const userPricesSlice = createSlice({
  name: 'userPrices',
  initialState,
  reducers: {
    setUserPrices: (state, { payload }) => {
      const _pricesUngroupedList = {};
      payload.forEach((g) => {
        g.list.forEach((el) => {
          _pricesUngroupedList[el.endpoint] = el;
        });
      });

      state.data = {
        prices: payload,
        groupsList: payload.map((el) => el.name),
        pricesUngroupedList: _pricesUngroupedList,
      };
      state.loading = false;
      state.error = '';
    },
    setUserPricesError: (state, { payload }) => {
      state.data = {
        prices: [],
        groupsList: [],
      };
      state.loading = false;
      state.error = payload;
    },
    resetUserPrices: () => initialState,
  },
});

export const { setUserPrices, setUserPricesError, resetUserPrices } = userPricesSlice.actions;

export default userPricesSlice.reducer;
