import { Fragment } from 'react';
import { Skeleton, Card, Grid, Typography, Box, Divider } from '@mui/material';

export const GeneralInfoSkeleton = () => (
  <Card sx={{ marginBottom: 3, paddingY: 3, paddingX: 4 }} variant='outlined'>
    <Grid container justifyContent='space-between' sx={{ marginBottom: 1 }}>
      <Typography variant='h4'>
        <Skeleton variant='text' width={135} />
      </Typography>
      <Skeleton width={236} height={32} variant='rounded' />
    </Grid>
    <Typography variant='body1'>
      <Skeleton variant='text' width={200} />
    </Typography>
    <Grid container alignItems='center'>
      <Typography variant='body1' sx={{ marginRight: 1 }}>
        <Skeleton variant='text' width={330} />
      </Typography>
      <Skeleton width={24} height={24} variant='circular' />
    </Grid>
  </Card>
);

export const TableSkeleton = ({ rowHeight = 53, tHeadHeight = 55.5 }) => (
  <Fragment>
    <Grid container alignItems='center' sx={{ height: tHeadHeight, paddingX: 4 }}>
      <Typography variant='caption' sx={{ width: '100%' }}>
        <Skeleton variant='text' />
      </Typography>
    </Grid>
    <Divider />
    <Grid container flexDirection='column' alignItems='center' sx={{ height: (rowHeight + 1) * 10 }}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((el) => (
        <Fragment key={el}>
          <Grid container alignItems='center' sx={{ height: rowHeight, paddingX: 4 }}>
            <Typography variant='body1' sx={{ width: '100%' }}>
              <Skeleton variant='text' />
            </Typography>
          </Grid>
          <Divider sx={{ width: '100%' }} />
        </Fragment>
      ))}
    </Grid>
    <Box sx={{ marginBottom: 3 }} />
    <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
      <Skeleton variant='rounded' width={150} height={26} />
    </Grid>
  </Fragment>
);

export const ChartSkeleton = ({ chartBlockHeight = 214 }) => (
  <Box sx={{ marginX: 2 }}>
    <Skeleton height={chartBlockHeight} width='100%' variant='rounded' sx={{ marginBottom: 2 }} />
    <Skeleton height={12} width='100%' variant='rounded' />
  </Box>
);

export const PricesGroupedSkeleton = () => (
  <Fragment>
    <Box sx={{ paddingX: 4 }}>
      <Skeleton height={48} sx={{ paddingY: 2 }} />
      <TableSkeleton />
    </Box>
  </Fragment>
);

export const EndpointConsumptionSkeleton = ({ rowHeight = 53, tHeadHeight = 55.5 }) => (
  <Fragment>
    <Grid container alignItems='center' sx={{ height: tHeadHeight, paddingX: 4 }}>
      <Typography variant='caption' sx={{ width: '100%' }}>
        <Skeleton variant='text' />
      </Typography>
    </Grid>
    <Divider />
    <Grid container flexDirection='column' alignItems='center' sx={{ height: (rowHeight + 1) * 5 }}>
      {[0, 1, 2, 3, 4].map((el) => (
        <Fragment key={el}>
          <Grid container alignItems='center' sx={{ height: rowHeight, paddingX: 4 }}>
            <Typography variant='body1' sx={{ width: '100%' }}>
              <Skeleton variant='text' />
            </Typography>
          </Grid>
          <Divider sx={{ width: '100%' }} />
        </Fragment>
      ))}
    </Grid>
  </Fragment>
);
