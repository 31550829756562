import { Fragment } from 'react';
import { Card, Typography, Grid, Skeleton, Box, Divider } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import { ButtonComponent } from 'components/ui';

export const PageSkeleton = () => {
  return (
    <Card variant='outlined' sx={{ marginBottom: 5 }}>
      <Box sx={{ padding: 4 }}>
        <Typography variant='h4' mb='8px'>
          <Skeleton width={150} variant='text' />
        </Typography>
        <Grid container alignItems='flex-end'>
          <Typography variant='h1'>
            <Skeleton width={200} variant='text' />
          </Typography>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ padding: 4, paddingBottom: 5.5 }}>
        <Typography variant='h4' mb='20px'>
          <Skeleton width={150} variant='text' />
        </Typography>
        <Skeleton sx={{ marginBottom: '30px' }} height={24} variant='rounded' />
        <Typography variant='h4' mb='20px'>
          <Skeleton width={150} variant='text' />
        </Typography>
        <Skeleton height={36} width='calc(25% - 15px)' variant='rounded' />
      </Box>
    </Card>
  );
};

export const ReaponseSkeleton = () => {
  return (
    <Fragment>
      <Grid container justifyContent='space-between' mb='20px' paddingX={4}>
        <Typography variant='h4'>Response Data</Typography>
        <ButtonComponent width={90} color='inherit' variant='outlined' height={30} text='JSON' endIcon={<DownloadRoundedIcon fontSize='small' />} disabled />
      </Grid>
      <Box sx={{ paddingX: 4, position: 'relative' }}>
        <Skeleton width='100%' height={531} variant='rounded' />
      </Box>
    </Fragment>
  );
};
