import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  loading: true,
  error: '',
};

export const subscriptionOptionsSlice = createSlice({
  name: 'subscriptionOptions',
  initialState,
  reducers: {
    setSubscriptionOptions: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = '';
    },
    setSubscriptionOptionsError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetSubscriptionOptions: () => initialState,
  },
});

export const { setSubscriptionOptions, setSubscriptionOptionsError, resetSubscriptionOptions } = subscriptionOptionsSlice.actions;

export default subscriptionOptionsSlice.reducer;
