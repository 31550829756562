import { Card, Grid, Typography, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSearchParams } from 'react-router-dom';

import { StarIcon } from 'components';
import { numberWithCommas } from 'helpers';
import { amount } from 'pages/user/purchaseUnits/page.const';

export const SuccessCard = () => {
  const [searchParams] = useSearchParams();
  const {
    palette: {
      primary: { main: mainBlue },
    },
  } = useTheme();

  const amountVal = searchParams.get(amount);

  return (
    <Card sx={{ height: 500, padding: 4 }} variant='outlined'>
      <Grid container sx={{ height: '100%' }} direction='column' alignItems='center' justifyContent='center'>
        <Grid sx={{ width: 100, height: 100, borderRadius: 50, marginBottom: 2.5 }} bgcolor={`${mainBlue}16`} container alignItems='center' justifyContent='center'>
          <StarIcon size={42} color={mainBlue} />
        </Grid>
        <Typography variant='h4' mb={1.2}>
          Thank you!
        </Typography>
        <Grid sx={{ width: 333 }}>
          <Typography variant='body2' textAlign='center' color='text.secondary'>
            {
              <Typography component='span' color='text.primary'>
                {numberWithCommas(+amountVal)} units purchased
              </Typography>
            }
            , they will be added to your account shortly.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export const ErrorCard = ({ message }) => {
  const {
    palette: {
      error: { main: red },
    },
  } = useTheme();

  return (
    <Card sx={{ height: 500, padding: 4 }} variant='outlined'>
      <Grid container sx={{ height: '100%' }} direction='column' alignItems='center' justifyContent='center'>
        <Grid sx={{ width: 100, height: 100, borderRadius: 50, marginBottom: 2.5 }} bgcolor={`${red}16`} container alignItems='center' justifyContent='center'>
          <ErrorOutlineIcon sx={{ width: 42, height: 42 }} color='error' />
        </Grid>
        <Typography variant='h4' mb={1.2} color='text.red'>
          Error
        </Typography>
        <Grid sx={{ width: 333 }}>
          <Typography variant='body2' textAlign='center' color='text.red'>
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
