import { Fragment } from 'react';

import { Card, Typography, Grid, Skeleton } from '@mui/material';

import { baseAmounts, amountCardStyles, h2Styles, paymentCardStyles } from 'pages/user/purchaseUnits/page.const';

export const PurchaseUnitsSkeleton = () => {
  const renderBaseAmounts = () =>
    [...baseAmounts, 1].map((a, i) => {
      return (
        <Card key={`amount-skeleton-${a}-${i}`} variant='outlined' sx={amountCardStyles}>
          <Typography variant='h3' sx={h2Styles}>
            <Skeleton width='100%' variant='text' />
          </Typography>
          <Typography variant='h4' color='text.secondary'>
            <Skeleton width='100%' variant='text' />
          </Typography>
        </Card>
      );
    });

  const renderPaymentTypes = () =>
    [0, 1].map((el, i) => {
      return (
        <Card key={`payment-type-skeleton-${el}-${i}`} variant='outlined' sx={paymentCardStyles}>
          <Grid container alignItems='center' sx={{ height: '100%' }}>
            <Skeleton variant='circular' width={42} height={42} sx={{ marginRight: 2.5 }} />
            <Grid flexDirection='column' justifyContent='center' sx={{ height: '100%' }}>
              <Typography variant='h4' mb='5px'>
                <Skeleton width={100} variant='text' />
              </Typography>
              <Typography variant='subtitle1'>
                <Skeleton width={80} variant='text' />
              </Typography>
            </Grid>
          </Grid>
        </Card>
      );
    });

  return (
    <div>
      <Fragment>
        <Card variant='outlined' sx={{ padding: 4, paddingBottom: 5, marginBottom: 5.5, height: 210 }}>
          <Typography variant='h4' sx={{ marginBottom: 3 }}>
            <Skeleton width={200} variant='text' />
          </Typography>
          <Grid container alignItems='center' justifyContent='space-between'>
            {renderBaseAmounts()}
          </Grid>
        </Card>
        <Card variant='outlined' sx={{ padding: 4, paddingBottom: 5, marginBottom: 5 }}>
          <Typography variant='h4' sx={{ marginBottom: '20px' }}>
            <Skeleton width={200} variant='text' />
          </Typography>
          <Grid container alignItems='center' justifyContent='space-between'>
            {renderPaymentTypes()}
          </Grid>
        </Card>
        <Card variant='outlined' sx={{ padding: 4, paddingBottom: 5, marginBottom: 5 }}>
          <Typography variant='h4' sx={{ marginBottom: '20px' }}>
            <Skeleton width={200} variant='text' />
          </Typography>
          <Skeleton variant='rounded' sx={{ height: 42, marginBottom: 4 }} />
          <Skeleton variant='rounded' sx={{ height: 42, width: 375 }} />
        </Card>
      </Fragment>
    </div>
  );
};
