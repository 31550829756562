import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    environment: {},
    product: {},
    type: {},
  },
  loading: true,
  error: '',
};

export const registrationOptionsSlice = createSlice({
  name: 'registrationOptions',
  initialState,
  reducers: {
    setRegistrationOptions: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.error = '';
    },
    setRegistrationOptionsError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetRegistrationOptions: () => initialState,
  },
});

export const { setRegistrationOptions, setRegistrationOptionsError, resetRegistrationOptions } = registrationOptionsSlice.actions;

export default registrationOptionsSlice.reducer;
