import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography, Table, TableBody, TableCell, TableHead, TableRow, Pagination, Grid, Box, Alert, TableSortLabel, Tooltip } from '@mui/material';
import moment from 'moment';

import { FileIcon } from 'components';
import { TableSkeleton } from 'components/common/skeletons';
import { AdminApi } from 'api';
import { usePaginationState } from 'helpers/hooks';
import { numberWithCommas } from 'helpers';

import { pageSize, dateColWidth, typeColWidth, fixedColWidth } from 'pages/user/dashboard/const';
import { topUpHistoryKey } from 'store/slices/updateObserver';

export const InvoicesHistory = () => {
  const observer = useSelector((s) => s.updateObserver[topUpHistoryKey]);
  const { data, error, loading, totalPages, page, sortBy, sortOrder, onChangePage, onChangeSort } = usePaginationState(
    AdminApi.getApiUsersInvoices,
    'createDate',
    'DESC',
    pageSize,
    observer
  );

  const emptyRows = pageSize - data.length;

  const PageContent = useMemo(() => {
    return data.map((payment, index) => <TopUpItem {...{ payment, key: 'row-' + payment.id + +index + Math.random() + payment.userId }} />);
  }, [data]);

  return (
    <Card sx={{ paddingY: 4 }} variant='outlined'>
      <Typography variant='h4' pl={4}>
        Invoices History
      </Typography>
      {loading ? (
        <TableSkeleton />
      ) : error ? (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : data.length ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4, width: dateColWidth }}>
                  <Typography variant='caption' color='text.secondary'>
                    EMAIL
                  </Typography>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'createDate'} onClick={onChangeSort('createDate')}>
                    <Typography variant='caption' color='text.secondary'>
                      DATE
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: typeColWidth }}>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'paidDate'} onClick={onChangeSort('paidDate')}>
                    <Typography variant='caption' color='text.secondary'>
                      PAID DATE
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: fixedColWidth }}>
                  <Typography variant='caption' color='text.secondary'>
                    AMOUNT ($)
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: fixedColWidth }}>
                  <Typography variant='caption' color='text.secondary'>
                    INVOICE
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 540 }}>
              {PageContent}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 54 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginBottom: 3 }} />
          <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
            <Pagination shape='rounded' page={page} count={totalPages} onChange={onChangePage} size='small' color='primary' />
          </Grid>
        </Fragment>
      ) : (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='info'>No Info</Alert>
        </Box>
      )}
    </Card>
  );
};

const TopUpItem = ({ payment: { email, paidDate, createDate, url, amount } }) => {
  return (
    <TableRow sx={{ height: 53 }}>
      <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
        <Typography variant='body1' color='text.dark'>
          {email}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.secondary'>
          {moment(createDate).format('DD.MM.YYYY hh:mm A')}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1' color='text.dark'>
          {paidDate ? moment(paidDate).format('DD.MM.YYYY hh:mm A') : '-'}
        </Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        <Typography variant='body1'>{numberWithCommas(amount)}</Typography>
      </TableCell>
      <TableCell {...{ component: 'th', scope: 'row' }}>
        {url ? (
          <Tooltip
            title={
              <Typography variant='body2' color='text.dark'>
                Invoice Link
              </Typography>
            }
            arrow
            placement='top'
          >
            <a href={url} rel='noreferrer' target='_blank' style={{ textDecoration: 'none' }}>
              <Grid container alignItems='center' sx={{ cursor: 'pointer' }}>
                <FileIcon />
                <Typography sx={{ marginLeft: '5px' }} variant='body1' color='primary.main'>
                  Invoice
                </Typography>
              </Grid>
            </a>
          </Tooltip>
        ) : (
          <Typography variant='body1' color='text.dark'>
            -
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};
