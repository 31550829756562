import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, Divider, Grid, Typography, Skeleton, Alert } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

import { Copy } from 'components/icons';
import { Notification } from 'components';
import { ButtonComponent } from 'components/ui';
import { numberWithCommas } from 'helpers';
import { AdminApi } from 'api';
import { ResetUserApiKeyModal } from 'components/common/modals';
import { AddUnitsModal } from 'components/common/unitsModal';
import { currentUserBalanceKey } from 'store/slices/updateObserver';
import { CreateInvoiceModal } from 'pages/admin/userDetails/createInvoiceModal';

export const UserMainInfo = ({ currentUnitsPerDollar }) => {
  const observer = useSelector((s) => s.updateObserver[currentUserBalanceKey]);
  const { userId } = useParams();
  const [{ accountBalance, accountBalanceTrial, loading: balanceLoading, error: balanceError }, setAccountState] = useState({
    accountBalance: 0,
    accountBalanceTrial: 0,
    loading: true,
    error: '',
  });
  const [{ email, accessKey, loading: userInfoLoading, error: userInfoError }, setBaseInfo] = useState({
    accessKey: '',
    email: '',
    loading: true,
    error: '',
  });
  const [copied, setCopied] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [unitsModalOpen, setUnitsModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleUnitsModal = () => setUnitsModalOpen(!unitsModalOpen);

  const toggleInvoiceModal = () => setInvoiceModalOpen(!invoiceModalOpen);

  const onResetCopied = () => setCopied(false);

  const onCopyKey = () => navigator.clipboard.writeText(accessKey).then(() => setCopied(true));

  const getData = (currentUserId = userId) => {
    AdminApi.getUserBaseInfo(currentUserId)
      .then(({ accessKey, email }) => setBaseInfo({ accessKey, email, loading: false, error: '' }))
      .catch((error) => setBaseInfo({ accessKey: '', email: '', loading: false, error }));
  };

  const getBalance = (currentUserId = userId) => {
    Promise.all([AdminApi.getUserAccountBalance(currentUserId), AdminApi.getUserAccountBalance(currentUserId, true)])
      .then(([accountBalance, accountBalanceTrial]) => setAccountState({ accountBalance, accountBalanceTrial, loading: false, error: '' }))
      .catch((error) => setAccountState({ accountBalance: 0, loading: false, error }));
  };

  useEffect(() => {
    getData(userId); // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    getBalance(userId); // eslint-disable-next-line
  }, [userId, observer]);

  const error = userInfoError || balanceError;
  const disableUnitsModal = !currentUnitsPerDollar || typeof currentUnitsPerDollar !== 'number' || !email;

  return (
    <Fragment>
      <Card variant='outlined' sx={{ mb: '40px' }}>
        {error ? (
          <Grid sx={{ padding: 4 }}>
            <Alert severity='error'>{error}</Alert>
          </Grid>
        ) : (
          <Fragment key='content-info'>
            <Grid container sx={{ padding: 4 }} height={156} justifyContent='space-between'>
              <Grid container direction='column'>
                <Grid container justifyContent='space-between' mb='-5px'>
                  <Typography variant='h4'>Account Balance</Typography>
                  <ButtonComponent
                    text='Invoice'
                    width={95}
                    height={36}
                    endIcon={<DescriptionRoundedIcon fontSize='small' />}
                    disabled={userInfoLoading}
                    onClick={toggleInvoiceModal}
                    variant='outlined'
                    marginLeft='auto'
                    marginRight='20px'
                  />
                  <ButtonComponent
                    text='Add Units'
                    width={120}
                    height={36}
                    endIcon={<ControlPointIcon fontSize='small' />}
                    disabled={disableUnitsModal}
                    onClick={toggleUnitsModal}
                  />
                </Grid>
                <Grid container alignItems='flex-end' sx={{ marginBottom: '8px' }}>
                  {balanceLoading ? (
                    <Typography variant='h1'>
                      <Skeleton sx={{ display: 'inline-block' }} width={200} variant='text' />
                    </Typography>
                  ) : (
                    <Fragment>
                      <Typography variant='h1' sx={{ marginRight: 1 }}>
                        {numberWithCommas(accountBalance) || 0}
                      </Typography>
                      <Typography variant='body1' sx={{ marginBottom: 1 }}>
                        units {accountBalanceTrial ? `(including ${accountBalanceTrial} trial)` : null}
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
                <Typography variant='body1' color='text.secondary'>
                  E-mail:{' '}
                  <Typography variant='body1' component='span' color='text.primary'>
                    {userInfoLoading ? <Skeleton sx={{ display: 'inline-block' }} width={200} variant='text' /> : email}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ padding: '15px 24px', height: 58 }} alignItems='center'>
              <Typography variant='body1' color='text.secondary' mr={2}>
                User API Key:
              </Typography>
              {userInfoLoading ? (
                <Skeleton sx={{ width: 290, height: 30 }} variant='rounded' />
              ) : (
                <Grid
                  container
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ width: 290, height: 30, borderRadius: 1, cursor: 'pointer', bgcolor: '#F2F8FF', padding: '6px 14px' }}
                  onClick={onCopyKey}
                >
                  <Typography variant='body1' maxWidth='calc(100% - 20px)' noWrap>
                    {accessKey}
                  </Typography>
                  <Copy />
                </Grid>
              )}
              <ButtonComponent disabled={userInfoLoading} text='Reset API Key' color='error' marginLeft='auto' variant='outlined' width={120} height={30} onClick={toggleModal} />
            </Grid>
          </Fragment>
        )}
      </Card>
      <Notification open={copied} onClose={onResetCopied} message='API key copied to clipboard' />
      <ResetUserApiKeyModal {...{ open: modalOpen, onClose: toggleModal, email, key: `reset-user-api-key-modal-${email}-${String(modalOpen)}`, userId, updateData: getData }} />
      <AddUnitsModal
        currentUnitsPerDollar={currentUnitsPerDollar}
        key={String(unitsModalOpen)}
        open={unitsModalOpen}
        onClose={toggleUnitsModal}
        updateData={getBalance}
        email={email}
      />
      <CreateInvoiceModal
        currentUnitsPerDollar={currentUnitsPerDollar}
        userId={userId}
        key={'create-invoice-modal-' + String(invoiceModalOpen)}
        open={invoiceModalOpen}
        onClose={toggleInvoiceModal}
      />
    </Fragment>
  );
};
