import { useState, useEffect } from 'react';
import { Card, Typography, Grid, Alert, Skeleton } from '@mui/material';

import { AdminApi } from 'api';
import { numberWithCommas } from 'helpers';

export const AdminBalance = () => {
  const [balace, setBalance] = useState({});
  const [loading, setLoading] = useState(true);
  const [balanceError, setBalanceError] = useState('');

  useEffect(() => {
    AdminApi.getAdminBalance()
      .then(setBalance)
      .catch(setBalanceError)
      .finally(() => setLoading(false));
  }, []);

  const renderBalances = () => {
    return Object.entries(balace).map(([key, val], i, { length }) => {
      return (
        <Grid container width='fit-content' direction='column' mr={i < length - 1 ? '100px' : 0} key={key + val}>
          <Typography key={key} variant='body1' color='text.secondary' mb={1}>
            {key}
          </Typography>
          <Typography variant='h3' color='text.primary'>
            {numberWithCommas(val)}
          </Typography>
        </Grid>
      );
    });
  };

  return (
    <Card sx={{ marginBottom: 3, paddingY: 3, paddingX: 4 }} variant='outlined'>
      <Typography variant='h4' mb={1}>
        Global Units Balance
      </Typography>
      {balanceError ? (
        <Alert severity='error'>{balanceError}</Alert>
      ) : loading ? (
        <Grid container>
          <Grid container width='fit-content' direction='column' mr='100px'>
            <Typography variant='body1' mb={1}>
              <Skeleton width={80} variant='text' />
            </Typography>
            <Typography variant='h3' color='text.primary'>
              <Skeleton width={150} variant='text' />
            </Typography>
          </Grid>
          <Grid container width='fit-content' direction='column' mr='100px'>
            <Typography variant='body1' mb={1}>
              <Skeleton width={80} variant='text' />
            </Typography>
            <Typography variant='h3' color='text.primary'>
              <Skeleton width={150} variant='text' />
            </Typography>
          </Grid>
          <Grid container width='fit-content' direction='column'>
            <Typography variant='body1' mb={1}>
              <Skeleton width={80} variant='text' />
            </Typography>
            <Typography variant='h3' color='text.primary'>
              <Skeleton width={150} variant='text' />
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container>{renderBalances()}</Grid>
      )}
    </Card>
  );
};
