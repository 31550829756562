import { useTheme } from '@mui/material/styles';

export const Copy = () => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_331_683)'>
        <path
          d='M11.6667 5.25H6.41667C5.77233 5.25 5.25 5.77233 5.25 6.41667V11.6667C5.25 12.311 5.77233 12.8333 6.41667 12.8333H11.6667C12.311 12.8333 12.8333 12.311 12.8333 11.6667V6.41667C12.8333 5.77233 12.311 5.25 11.6667 5.25Z'
          stroke={main}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.91663 8.75008H2.33329C2.02387 8.75008 1.72713 8.62717 1.50833 8.40837C1.28954 8.18958 1.16663 7.89283 1.16663 7.58342V2.33341C1.16663 2.024 1.28954 1.72725 1.50833 1.50846C1.72713 1.28966 2.02387 1.16675 2.33329 1.16675H7.58329C7.89271 1.16675 8.18946 1.28966 8.40825 1.50846C8.62704 1.72725 8.74996 2.024 8.74996 2.33341V2.91675'
          stroke={main}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_331_683'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ShoppingCart = ({ color }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();

  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_331_1055)'>
        <path
          d='M4.5 11C4.77614 11 5 10.7761 5 10.5C5 10.2239 4.77614 10 4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11Z'
          stroke={color || contrastText}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 11C10.2761 11 10.5 10.7761 10.5 10.5C10.5 10.2239 10.2761 10 10 10C9.72386 10 9.5 10.2239 9.5 10.5C9.5 10.7761 9.72386 11 10 11Z'
          stroke={color || contrastText}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.5 0.5H2.5L3.84 7.195C3.88572 7.4252 4.01095 7.63198 4.19377 7.77915C4.37659 7.92632 4.60535 8.0045 4.84 8H9.7C9.93465 8.0045 10.1634 7.92632 10.3462 7.77915C10.529 7.63198 10.6543 7.4252 10.7 7.195L11.5 3H3'
          stroke={color || contrastText}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_331_1055'>
          <rect width='12' height='12' fill={color || contrastText} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DoorOut = ({ color }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();

  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.25 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V2.91667C1.75 2.60725 1.87292 2.3105 2.09171 2.09171C2.3105 1.87292 2.60725 1.75 2.91667 1.75H5.25'
        stroke={color || contrastText}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.33333 9.91659L12.25 6.99992L9.33333 4.08325' stroke={color || contrastText} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.25 7H5.25' stroke={color || contrastText} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const ExternalLink = ({ color }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();

  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 7.58333V11.0833C10.5 11.3928 10.3771 11.6895 10.1583 11.9083C9.9395 12.1271 9.64275 12.25 9.33333 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V4.66667C1.75 4.35725 1.87292 4.0605 2.09171 3.84171C2.3105 3.62292 2.60725 3.5 2.91667 3.5H6.41667'
        stroke={color || contrastText}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.75 1.75H12.25V5.25' stroke={color || contrastText} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.83331 8.16667L12.25 1.75' stroke={color || contrastText} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const Info = ({ size = 10 }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg width={size} height={size} viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_229_2091)'>
        <path
          d='M4.5 8.75C6.57107 8.75 8.25 7.07107 8.25 5C8.25 2.92893 6.57107 1.25 4.5 1.25C2.42893 1.25 0.75 2.92893 0.75 5C0.75 7.07107 2.42893 8.75 4.5 8.75Z'
          stroke={secondary}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M4.5 6.5V5' stroke={secondary} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.5 3.5H4.50375' stroke={secondary} strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_229_2091'>
          <rect width='9' height='9' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Calendar = () => {
  const {
    palette: {
      text: { linearGrey },
    },
  } = useTheme();

  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.25 6.33325H4.08333V7.49992H5.25V6.33325ZM7.58333 6.33325H6.41667V7.49992H7.58333V6.33325ZM9.91667 6.33325H8.75V7.49992H9.91667V6.33325ZM11.0833 2.24992H10.5V1.08325H9.33333V2.24992H4.66667V1.08325H3.5V2.24992H2.91667C2.26917 2.24992 1.75 2.77492 1.75 3.41659V11.5833C1.75 11.8927 1.87292 12.1894 2.09171 12.4082C2.3105 12.627 2.60725 12.7499 2.91667 12.7499H11.0833C11.3928 12.7499 11.6895 12.627 11.9083 12.4082C12.1271 12.1894 12.25 11.8927 12.25 11.5833V3.41659C12.25 3.10717 12.1271 2.81042 11.9083 2.59163C11.6895 2.37283 11.3928 2.24992 11.0833 2.24992ZM11.0833 11.5833H2.91667V5.16659H11.0833V11.5833Z'
        fill={linearGrey}
      />
    </svg>
  );
};

export const ApiIcon = ({ size = 16 }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();
  return (
    <svg height={size} viewBox='0 0 48 48' width={size} xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0h48v48h-48z' fill='none' />
      <path
        fill={contrastText}
        d='M26 4.1v6.06c6.78.97 12 6.79 12 13.84 0 1.79-.35 3.5-.96 5.07l5.2 3.07c1.11-2.49 1.76-5.23 1.76-8.14 0-10.37-7.89-18.89-18-19.9zm-2 33.9c-7.73 0-14-6.27-14-14 0-7.05 5.22-12.87 12-13.84v-6.06c-10.12 1-18 9.53-18 19.9 0 11.05 8.94 20 19.99 20 6.62 0 12.47-3.23 16.11-8.18l-5.19-3.06c-2.56 3.19-6.49 5.24-10.91 5.24z'
      />
    </svg>
  );
};

export const UserIcon = ({ size = 16, color, strokeWidth = 2 }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 16' fill='none'>
      <path
        d='M13.3332 14V12.6667C13.3332 11.9594 13.0522 11.2811 12.5521 10.781C12.052 10.281 11.3737 10 10.6665 10H5.33317C4.62593 10 3.94765 10.281 3.44755 10.781C2.94746 11.2811 2.6665 11.9594 2.6665 12.6667V14'
        stroke={color || contrastText}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={strokeWidth}
      />
      <path
        d='M8.00016 7.33333C9.47292 7.33333 10.6668 6.13943 10.6668 4.66667C10.6668 3.19391 9.47292 2 8.00016 2C6.5274 2 5.3335 3.19391 5.3335 4.66667C5.3335 6.13943 6.5274 7.33333 8.00016 7.33333Z'
        stroke={color || contrastText}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const HubspotIcon = ({ size = 12 }) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12 12' fill='none'>
      <g clipPath='url(#clip0_742_1750)'>
        <path
          d='M6.26738 4.95938C5.6791 5.51484 5.31113 6.30234 5.31113 7.17656C5.31113 7.86328 5.53848 8.49609 5.92051 9.00469L4.76035 10.1719C4.65723 10.1344 4.54707 10.1133 4.43223 10.1133C4.1791 10.1133 3.94238 10.2117 3.76426 10.3898C3.58613 10.568 3.4877 10.807 3.4877 11.0602C3.4877 11.3133 3.58613 11.55 3.76426 11.7281C3.94238 11.9062 4.18145 12 4.43223 12C4.68535 12 4.92207 11.9086 5.10254 11.7281C5.28066 11.55 5.3791 11.3109 5.3791 11.0602C5.3791 10.9617 5.36504 10.868 5.33457 10.7766L6.50645 9.6C7.02207 9.99609 7.66426 10.2305 8.36504 10.2305C10.0502 10.2305 11.4119 8.86406 11.4119 7.17891C11.4119 5.65078 10.2939 4.38516 8.8291 4.1625V2.71875C9.23926 2.54531 9.49004 2.16094 9.49004 1.71328C9.49004 1.10156 9.00019 0.590625 8.38848 0.590625C7.77676 0.590625 7.29395 1.10156 7.29395 1.71328C7.29395 2.16094 7.54473 2.54531 7.95488 2.71875V4.15312C7.59863 4.20234 7.26113 4.31016 6.9541 4.47188C6.30723 3.98203 4.2002 2.46328 2.99551 1.54688C3.02363 1.44375 3.04238 1.33594 3.04238 1.22344C3.04238 0.548438 2.4916 0 1.81426 0C1.13926 0 0.59082 0.548438 0.59082 1.22344C0.59082 1.90078 1.13926 2.44922 1.81426 2.44922C2.04395 2.44922 2.25723 2.38125 2.44238 2.27109L6.26738 4.95938ZM8.36504 8.79375C7.47207 8.79375 6.74785 8.06953 6.74785 7.17656C6.74785 6.28359 7.47207 5.55937 8.36504 5.55937C9.25801 5.55937 9.98223 6.28359 9.98223 7.17656C9.98223 8.06953 9.25801 8.79375 8.36504 8.79375Z'
          fill={main}
        />
      </g>
      <defs>
        <clipPath id='clip0_742_1750'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const EyeIcon = ({ size = 12 }) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12 12' fill='none'>
      <path d='M0.5 6C0.5 6 2.5 2 6 2C9.5 2 11.5 6 11.5 6C11.5 6 9.5 10 6 10C2.5 10 0.5 6 0.5 6Z' stroke='#5DC3F9' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z'
        stroke={main}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const EditIcon = ({ size = 12, color }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12 12' fill='none'>
      <g clipPath='url(#clip0_730_1791)'>
        <path
          style={{ transition: '0.25s linear stroke' }}
          d='M8.5 1.49991C8.63132 1.36859 8.78722 1.26441 8.9588 1.19334C9.13038 1.12227 9.31428 1.08569 9.5 1.08569C9.68572 1.08569 9.86962 1.12227 10.0412 1.19334C10.2128 1.26441 10.3687 1.36859 10.5 1.49991C10.6313 1.63123 10.7355 1.78713 10.8066 1.95871C10.8776 2.13029 10.9142 2.31419 10.9142 2.49991C10.9142 2.68562 10.8776 2.86952 10.8066 3.0411C10.7355 3.21268 10.6313 3.36859 10.5 3.49991L3.75 10.2499L1 10.9999L1.75 8.24991L8.5 1.49991Z'
          stroke={color || contrastText}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_730_1791'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CrossIcon = ({ size = 12, color }) => {
  const {
    palette: {
      text: { red },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12 12' fill='none'>
      <path d='M9 3L3 9' stroke={color || red} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3 3L9 9' stroke={color || red} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const CheckIcon = ({ size = 12, color }) => {
  const {
    palette: {
      text: { green },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12 12' fill='none'>
      <path d='M10 3L4.5 8.5L2 6' stroke={color || green} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const Code = ({ color }) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path d='M8 9L11 6L8 3' stroke={color || main} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 3L1 6L4 9' stroke={color || main} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const ClockIcon = ({ size = 12, color }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();
  return (
    <svg width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_730_1659)'>
        <path
          d='M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z'
          stroke={color || secondary}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M6 3V6L8 7' stroke={color || secondary} strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_730_1659'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CreditCardIcon = ({ size = 18, color }) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18 18' fill='none'>
      <path
        d='M15.75 3H2.25C1.42157 3 0.75 3.67157 0.75 4.5V13.5C0.75 14.3284 1.42157 15 2.25 15H15.75C16.5784 15 17.25 14.3284 17.25 13.5V4.5C17.25 3.67157 16.5784 3 15.75 3Z'
        stroke={color || main}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.75 7.5H17.25' stroke={color || main} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const USDCIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_742_2990)'>
        <path d='M8 16C12.4334 16 16 12.4334 16 8C16 3.56664 12.4334 0 8 0C3.56664 0 0 3.56664 0 8C0 12.4334 3.56664 16 8 16Z' fill='#2775CA' />
        <path
          d='M10.2 9.26665C10.2 8.10001 9.49998 7.70001 8.09998 7.53337C7.09998 7.40001 6.89998 7.13337 6.89998 6.66665C6.89998 6.19993 7.23334 5.90001 7.89998 5.90001C8.49998 5.90001 8.83334 6.10001 8.99998 6.60001C9.03334 6.70001 9.13334 6.76665 9.23334 6.76665H9.76662C9.89998 6.76665 9.99998 6.66665 9.99998 6.53337V6.50001C9.86662 5.76665 9.26662 5.20001 8.49998 5.13337V4.33337C8.49998 4.20001 8.39998 4.10001 8.23334 4.06665H7.73334C7.59998 4.06665 7.49998 4.16665 7.46662 4.33337V5.10001C6.46662 5.23337 5.83334 5.90001 5.83334 6.73337C5.83334 7.83337 6.49998 8.26665 7.89998 8.43337C8.83334 8.60001 9.13334 8.80001 9.13334 9.33337C9.13334 9.86673 8.66662 10.2334 8.03334 10.2334C7.16662 10.2334 6.86662 9.86665 6.76662 9.36665C6.73334 9.23337 6.63334 9.16665 6.53334 9.16665H5.96662C5.83334 9.16665 5.73334 9.26665 5.73334 9.40001V9.43337C5.86662 10.2667 6.39998 10.8667 7.49998 11.0334V11.8334C7.49998 11.9667 7.59998 12.0667 7.76662 12.1H8.26662C8.39998 12.1 8.49998 12 8.53334 11.8334V11.0334C9.53334 10.8667 10.2 10.1667 10.2 9.26665Z'
          fill='white'
        />
        <path
          d='M6.29998 12.7665C3.69998 11.8333 2.36662 8.93325 3.33334 6.36653C3.83334 4.96653 4.93334 3.89989 6.29998 3.39989C6.43334 3.33325 6.49998 3.23325 6.49998 3.06653V2.59989C6.49998 2.46653 6.43334 2.36653 6.29998 2.33325C6.26662 2.33325 6.19998 2.33325 6.16662 2.36653C2.99998 3.36653 1.26662 6.73325 2.26662 9.89989C2.86662 11.7665 4.29998 13.1999 6.16662 13.7999C6.29998 13.8665 6.43334 13.7999 6.46662 13.6665C6.49998 13.6333 6.49998 13.5999 6.49998 13.5333V13.0665C6.49998 12.9665 6.39998 12.8333 6.29998 12.7665ZM9.83334 2.36653C9.69998 2.29989 9.56662 2.36653 9.53334 2.49989C9.49998 2.53325 9.49998 2.56653 9.49998 2.63325V3.09989C9.49998 3.23325 9.59998 3.36653 9.69998 3.43325C12.3 4.36653 13.6333 7.26653 12.6666 9.83325C12.1666 11.2333 11.0666 12.2999 9.69998 12.7999C9.56662 12.8665 9.49998 12.9665 9.49998 13.1333V13.5999C9.49998 13.7333 9.56662 13.8333 9.69998 13.8665C9.73334 13.8665 9.79998 13.8665 9.83334 13.8333C13 12.8333 14.7333 9.46653 13.7333 6.29989C13.1333 4.39989 11.6666 2.96653 9.83334 2.36653Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_742_2990'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChewronRightIcon = ({ color, size = 12 }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 12 12' fill='none'>
      <path d='M4.5 9L7.5 6L4.5 3' stroke={color || contrastText} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const StarIcon = ({ color, size = 16 }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 42 42' fill='none'>
      <path
        d='M21 3.5L26.4075 14.455L38.5 16.2225L29.75 24.745L31.815 36.785L21 31.0975L10.185 36.785L12.25 24.745L3.5 16.2225L15.5925 14.455L21 3.5Z'
        stroke={color || contrastText}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const CheckBoxIcon = () => {
  const {
    palette: {
      border: { main },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H16C16.8284 0.5 17.5 1.17157 17.5 2V16C17.5 16.8284 16.8284 17.5 16 17.5H2C1.17157 17.5 0.5 16.8284 0.5 16V2Z'
        fill='white'
        stroke={main}
      />
    </svg>
  );
};

export const CheckBoxCheckedIcon = () => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H16C16.8284 0.5 17.5 1.17157 17.5 2V16C17.5 16.8284 16.8284 17.5 16 17.5H2C1.17157 17.5 0.5 16.8284 0.5 16V2Z'
        fill='white'
        stroke={main}
      />
      <path d='M11.6666 3.5L5.24998 9.91667L2.33331 7' stroke={main} strokeLinecap='round' strokeLinejoin='round' style={{ transform: 'translate(2px, 2px)' }} />
    </svg>
  );
};

export const FileIcon = ({ color }) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M7.58334 1.16675H3.50001C3.19059 1.16675 2.89384 1.28966 2.67505 1.50846C2.45626 1.72725 2.33334 2.024 2.33334 2.33341V11.6667C2.33334 11.9762 2.45626 12.2729 2.67505 12.4917C2.89384 12.7105 3.19059 12.8334 3.50001 12.8334H10.5C10.8094 12.8334 11.1062 12.7105 11.325 12.4917C11.5438 12.2729 11.6667 11.9762 11.6667 11.6667V5.25008L7.58334 1.16675Z'
        stroke={color || main}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.58334 1.16675V5.25008H11.6667' stroke={color || main} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const CloseCircleIcon = ({ color, size = 20 }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20 20' fill='none'>
      <path
        d='M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 9.99999C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 9.99999C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z'
        stroke={color || secondary}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.5 7.5L7.5 12.5' stroke={color || secondary} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 7.5L12.5 12.5' stroke={color || secondary} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const SearchIcon = ({ size = 14, color }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 14 14' fill='none'>
      <path
        d='M6.41667 11.0833C8.994 11.0833 11.0833 8.994 11.0833 6.41667C11.0833 3.83934 8.994 1.75 6.41667 1.75C3.83934 1.75 1.75 3.83934 1.75 6.41667C1.75 8.994 3.83934 11.0833 6.41667 11.0833Z'
        stroke={color || secondary}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.2504 12.25L9.71289 9.71252' stroke={color || secondary} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const AlertIcon = ({ size = 10, color }) => {
  const {
    palette: {
      chart: { orange },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size * 1.1} viewBox='0 0 10 11' fill='none'>
      <path
        d='M4.28733 2.1083L0.758159 7.99996C0.685396 8.12597 0.646895 8.26884 0.646488 8.41435C0.64608 8.55985 0.68378 8.70293 0.755837 8.82935C0.827893 8.95576 0.931795 9.06111 1.0572 9.1349C1.18261 9.20869 1.32516 9.24836 1.47066 9.24996H8.52899C8.67449 9.24836 8.81704 9.20869 8.94245 9.1349C9.06786 9.06111 9.17176 8.95576 9.24381 8.82935C9.31587 8.70293 9.35357 8.55985 9.35316 8.41435C9.35276 8.26884 9.31426 8.12597 9.24149 7.99996L5.71233 2.1083C5.63805 1.98584 5.53346 1.8846 5.40866 1.81433C5.28385 1.74407 5.14305 1.70715 4.99983 1.70715C4.8566 1.70715 4.7158 1.74407 4.59099 1.81433C4.46619 1.8846 4.36161 1.98584 4.28733 2.1083Z'
        stroke={color || orange}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5 4.25V5.91667' stroke={color || orange} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 7.58337H5.005' stroke={color || orange} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const QuestionIcon = ({ size = 12, color }) => {
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme();

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_3725_281742)'>
        <path
          d='M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z'
          stroke={color || secondary}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M8 11.3334H8.00667' stroke={color || secondary} strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M6.05957 6.00001C6.21631 5.55446 6.52567 5.17875 6.93287 4.93944C7.34008 4.70012 7.81883 4.61264 8.28435 4.69249C8.74988 4.77234 9.17211 5.01436 9.47629 5.3757C9.78046 5.73703 9.94694 6.19436 9.94624 6.66668C9.94624 8.00001 7.94624 8.66668 7.94624 8.66668'
          stroke={color || secondary}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3725_281742'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CodeIcon = ({ size = 16, color, strokeWidth = 2 }) => {
  const {
    palette: {
      primary: { contrastText },
    },
  } = useTheme();
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 10 10' fill='none'>
      <g clipPath='url(#clip0_7791_11986)'>
        <path
          d='M7.5 3.125L8.66789 4.29289C9.05842 4.68342 9.05842 5.31658 8.66789 5.70711L7.5 6.875'
          stroke={color || contrastText}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.5 3.125L1.33211 4.29289C0.941583 4.68342 0.941583 5.31658 1.33211 5.70711L2.5 6.875'
          stroke={color || contrastText}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M5.625 1.25L4.375 8.75' stroke={color || contrastText} strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_7791_11986'>
          <rect width='10' height='10' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
