import { useState } from 'react';
import { Typography, Grid, Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material';
import { MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Card from '@mui/material/Card';

import { Calendar } from 'components/icons';
import { CustomSelect } from 'components/ui/selectPlaceholder/customSelect';

export const ParamsBlock = ({ params = [], paramsState = {}, onChangeField, onChangeDateField, disableFields }) => {
  const theme = useTheme();

  const componentsMap = {
    TEXT_FIELD: (param = {}, key) =>
      ['address', 'userAddress', 'lpTokenAddress'].includes(param.name) ? (
        <AddressField
          {...{
            onChangeField,
            param,
            disableFields,
            paramsState,
            key,
            options:
              param.name === 'lpTokenAddress'
                ? [
                    {
                      value: '0xd3d2e2692501a5c9ca623199d38826e513033A17',
                      label: 'Uniswap V2 UNI/WETH',
                    },
                    {
                      value: '0x49849c98ae39fff122806c06791fa73784fb3675',
                      label: 'Curve.fi renBTC/wBTC',
                    },
                    {
                      value: '0x075b1bb99792c9e1041ba13afef80c91a1e70fb3',
                      label: 'Curve.fi renBTC/wBTC/sBTC',
                    },
                  ]
                : undefined,
          }}
        />
      ) : (
        <TextField
          sx={{ width: 'calc(25% - 15px)', marginBottom: '20px' }}
          disabled={disableFields}
          key={key}
          required={param.isRequired}
          InputProps={{ sx: { fontSize: 12, height: 36 } }}
          name={param.name}
          variant='outlined'
          placeholder={param.label + (param.isRequired ? ' *' : '')}
          value={paramsState[param.name]}
          onChange={onChangeField}
          type={param.valueType === 'number' ? 'number' : 'text'}
        />
      ),
    DROP_DOWN: (param = {}, key) => (
      <CustomSelect
        {...{
          key,
          disabled: disableFields,
          value: paramsState[param.name],
          onChange: onChangeField,
          name: param.name,
          placeholder: param.label + (param.isRequired ? ' *' : ''),
          showPlaceholder: !paramsState[param.name] || (Array.isArray(paramsState[param.name]) && !paramsState[param.name].length),
          multiple: param.valueType === 'array',
          marginBottom: '20px',
        }}
      >
        {Object.entries(param.values).map(([key, val], index) => (
          <MenuItem value={key} key={key + val + index}>
            {val}
          </MenuItem>
        ))}
      </CustomSelect>
    ),
    DATE_PICKER: (param = {}, key) => (
      <DatePicker
        disabled={disableFields}
        key={key}
        inputFormat='DD/MM/YYYY'
        disableFuture
        label={param.label + (param.isRequired ? ' *' : '')}
        value={paramsState[param.name]}
        onChange={onChangeDateField(param.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              width: 'calc(25% - 15px)',
              marginBottom: '20px',
              '.MuiFormLabel-root': { fontSize: 12, top: paramsState[param.name] ? 0 : -6, color: theme.palette.text.secondary },
            }}
          />
        )}
        components={{ OpenPickerIcon: Calendar }}
        InputProps={{ sx: { color: theme.palette.text.black, height: 36, fontSize: 12 } }}
      />
    ),
  };

  const renderParams = () => params.map((el) => componentsMap[el.component](el, el.name));

  if (!params.length) return null;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Typography variant='h4' mt='20px' mb='20px'>
        Params
      </Typography>
      <Grid container justifyContent='space-between'>
        {renderParams()}
        <Box sx={{ width: 'calc(25% - 15px)' }} />
        <Box sx={{ width: 'calc(25% - 15px)' }} />
      </Grid>
    </LocalizationProvider>
  );
};

const AddressField = ({
  onChangeField,
  param,
  disableFields,
  paramsState,
  options = [{ label: 'Demo Wallet: 0x3764D79db51726E900a1380055F469eB6e2a7fD3', value: '0x3764D79db51726E900a1380055F469eB6e2a7fD3' }],
}) => {
  const [open, setOpen] = useState(false);
  const showItem = open && !paramsState[param.name];

  const onSelect = (value) => () => {
    onChangeField({ target: { name: param.name, value } });
  };

  const renderOptions = () =>
    options.map(({ label, value }) => (
      <MenuItem key={label} sx={{ cursor: showItem ? 'pointer' : 'auto' }} onClick={onSelect(value)}>
        {label}
      </MenuItem>
    ));

  return (
    <div style={{ width: 'calc(25% - 15px)', marginBottom: '20px', position: 'relative' }}>
      <TextField
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        autoComplete='off'
        fullWidth
        disabled={disableFields}
        required={param.isRequired}
        InputProps={{ sx: { fontSize: 12, height: 36 } }}
        name={param.name}
        variant='outlined'
        placeholder={param.label + (param.isRequired ? ' *' : '')}
        value={paramsState[param.name]}
        onChange={onChangeField}
        type={param.valueType === 'number' ? 'number' : 'text'}
      />
      <Card sx={{ minWidth: '100%', position: 'absolute', opacity: showItem ? 1 : '0' }}>{!paramsState[param.name] ? renderOptions() : null}</Card>
    </div>
  );
};
