import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Box, Typography, Alert, IconButton, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment from 'moment';

import { AdminApi } from 'api';
import { GeneralInfoSkeleton } from 'components/common/skeletons';
import { paths } from 'consts';

export const ProPlusRequests = ({ isEnterpriseRequests = false }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AdminApi[isEnterpriseRequests ? 'getEnterpriseRequests' : 'getProPlusRequests']()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [isEnterpriseRequests]);

  const onNavigateToDetails = (userID) => () => navigate(`/${paths.admin.path}/${paths.appUsers.path}/${userID}`);

  const renderRequests = () =>
    data.map(({ email, date, id }, index) => (
      <TableRow key={'row-' + index + Math.random() + id} sx={{ height: 57 }}>
        <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
          <Typography variant='body1'>{email || '-'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.secondary'>
            {date ? moment(date).format('DD.MM.YY , HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <IconButton size='small' onClick={onNavigateToDetails(id)}>
            <ChevronRightIcon fontSize='small' />
          </IconButton>
        </TableCell>
      </TableRow>
    ));

  return (
    <Card sx={{ paddingTop: 3, paddingBottom: 6, marginBottom: 3 }} variant='outlined'>
      <Typography variant='h4' sx={{ marginBottom: 2, paddingLeft: 4 }}>
        Requests for {isEnterpriseRequests ? 'Enterprise' : 'Pro Plus'}
      </Typography>
      {loading ? (
        <GeneralInfoSkeleton />
      ) : error ? (
        <Box sx={{ paddingX: 4 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : data.length ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4 }}>
                  <Typography variant='caption' color='text.secondary'>
                    E-Mail
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    Date
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 56 }} />
              </TableRow>
            </TableHead>
            <TableBody>{renderRequests()}</TableBody>
          </Table>
        </Fragment>
      ) : (
        <Box sx={{ paddingX: 4 }}>
          <Alert severity='info'>No New Requests</Alert>
        </Box>
      )}
    </Card>
  );
};
