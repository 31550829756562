import { configureStore } from '@reduxjs/toolkit';

import userReducer from 'store/slices/user';
import userBaseInfoReducer from 'store/slices/userBaseInfo';
import registrationOptionsReducer from 'store/slices/registrationOptions';
import buferReducer from 'store/slices/bufer';
import userPricesReducer from 'store/slices/userPrices';
import updateObserverReducer from 'store/slices/updateObserver';
import subscriptionOptionsReducer from 'store/slices/subscriptionOptions';

export const store = configureStore({
  reducer: {
    user: userReducer, //common (role , authorization)
    bufer: buferReducer, //common for modals
    userBaseInfo: userBaseInfoReducer, //common (current loggedin user info)
    registrationOptions: registrationOptionsReducer, //common (otions for user creation/edit)
    userPrices: userPricesReducer, // for user only (for calculations)
    updateObserver: updateObserverReducer, // for user now, force update data dynamicly
    subscriptionOptions: subscriptionOptionsReducer, //admin for manage Merlin users
  },
});
