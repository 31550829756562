export const amount = 'amount';
export const paymentSuccess = 'paymentSuccess';

export const baseAmounts = [100, 200, 2000, 4000];

export const amountCardStyles = {
  height: 110,
  width: 'calc(20% - 16px)',
  paddingTop: 2.5,
  paddingRight: '38px',
  paddingBottom: '22px',
  paddingLeft: '20px',
  cursor: 'pointer',
  position: 'relative',
};
export const radioButtonIconStyles = {
  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
  },
};
export const radioButtonStyles = {
  position: 'absolute',
  right: 1,
  top: 0,
  ...radioButtonIconStyles,
};
export const h2Styles = { marginBottom: '10px', fontSize: 32, lineHeight: '40px', fontWeight: 500, letterSpacing: '-0.64px' };
export const paymentCardStyles = { height: 80, width: 'calc(50% - 10px)', paddingX: 4, paddingY: '15px', paddingRight: '12px', cursor: 'pointer' };
