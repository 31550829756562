export const loginLocalStorageName = 'merlin-api-login-data';
//in px
export const headerHeight = 70;
export const wrapperWidth = 1100;
export const footerHeight = 80;
//in material 'spacing'
export const wrapperPaddingX = 4;
export const wrapperPaddingY = '40px';

export const ADMIN_ROLES = ['ADMIN', 'SUPER_ADMIN'];
export const USER_ROLES = ['API_USER'];

export const emailPathern = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const paths = {
  //general
  any: { name: '', path: '*' },
  empty: { name: '', path: '/' },
  //auth
  auth: { name: '', path: 'auth' },
  login: { name: 'Login', path: '' },
  varification: { name: 'Varification', path: 'verification' },
  //user app
  user: { name: '', path: 'user' },
  dashboard: { name: 'Dashboard', path: 'dashboard' },
  purchaseUnits: { name: 'Purchasing Units', path: 'purchase-units' },
  liveApiTest: { name: 'Live API Test', path: 'live-api-test' },
  //admin app
  admin: { name: '', path: 'admin' },
  users: { name: 'API Users', path: 'users' },
  appUsers: { name: 'APP Users', path: 'app-users' },
  promoCodes: { name: 'Promo Codes', path: 'promo-codes' },
};

export const subscriptionsMap = {
  FREE: 'Free',
  BASE: 'Crypto Enthusiast',
  PRO: 'Trader',
  PRO_PLUS: 'Fund',
  ENTERPRISE: 'Enterprise',
};
