import { Fragment, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableHead, TableCell, TableRow, TableBody, Card, Typography, Grid, Box, Pagination, Alert, IconButton, TableSortLabel, TextField } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';

import { TableSkeleton } from 'components/common/skeletons';

import { useDebounceState } from 'helpers/hooks';
import { AdminApi } from 'api';
import { paths, subscriptionsMap } from 'consts';

const pageSize = 10;
const rowHeight = 57;
const debounceTime = 450;

export const MerlinUsersInformation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState('creationDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const resetPage = useCallback(() => setPage(1), []);
  const [search, setSearch] = useDebounceState('', debounceTime, resetPage);
  const inpuRef = useRef(null);

  const onChangeSearch = (e) => setSearch(e.target.value);

  const onClearSearch = () => {
    if (inpuRef && inpuRef.current) {
      try {
        inpuRef.current.value = '';
      } catch (error) {
        console.log(error);
      }
    }
    setSearch('');
  };

  const onChangeSort = (sortKey) => () => {
    if (sortBy === sortKey) setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    else setSortBy(sortKey);
    setPage(1);
  };

  const PageContent = useMemo(() => {
    const onNavigateToDetails = (userID) => () => navigate(`/${paths.admin.path}/${paths.appUsers.path}/${userID}`);

    return data.map(({ email, subscriptionPlan, creationDate, subscriptionPlanUpdatedDate, lastLoginDate, subscriptionPlanExpirationDate, userId, isTester }, index) => (
      <TableRow key={'row-' + index + Math.random() + userId} sx={{ height: rowHeight }}>
        <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
          <Typography variant='body1'>{email || '-'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          {subscriptionPlan ? (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              sx={{ paddingX: 1.5, paddingY: 0.5, width: 'fit-content', height: '23px', borderRadius: 1 }}
              bgcolor={`plans.${subscriptionPlan}`}
            >
              <Typography color={subscriptionPlan === 'FREE' ? 'text.secondary' : 'text.light'} variant='caption'>
                {subscriptionsMap[subscriptionPlan] || subscriptionPlan}
              </Typography>
            </Grid>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1'>{isTester ? 'Trial' : 'Client'}</Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.secondary'>
            {moment(creationDate).format('DD.MM.YY , HH:mm')}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.secondary'>
            {subscriptionPlanUpdatedDate ? moment(subscriptionPlanUpdatedDate).format('DD.MM.YY , HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color={subscriptionPlan === 'FREE' ? 'text.red' : 'text.secondary'}>
            {subscriptionPlan === 'FREE' || !subscriptionPlanExpirationDate ? null : moment(subscriptionPlanExpirationDate).format('DD.MM.YY , HH:mm')}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.secondary'>
            {lastLoginDate ? moment(lastLoginDate).format('DD.MM.YY , HH:mm') : '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <IconButton size='small' onClick={onNavigateToDetails(userId)}>
            <ChevronRightIcon fontSize='small' />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [data, navigate]);

  const onChangePage = (_, nextPage) => setPage(nextPage);

  const emptyRows = pageSize - data.length;

  const getUsers = (_page = page, _sortBy = sortBy, _sortOrder = sortOrder, _search = search, controller) => {
    setLoading(true);
    AdminApi.getMerlinUsers({ page: _page - 1, pageSize, sortBy: _sortBy, sortOrder: _sortOrder, search: _search, baseInfo: false }, controller.signal)
      .then(({ content, totalPages }) => {
        setData(content);
        setTotalPages(totalPages);
        setLoading(false);
      })
      .catch((err) => {
        if (err !== 'canceled') {
          setError(err);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const controller = new AbortController();

    getUsers(page, sortBy, sortOrder, search, controller);

    return () => controller.abort(); // eslint-disable-next-line
  }, [page, sortBy, sortOrder, search]);

  return (
    <Card sx={{ paddingY: 3, marginBottom: 3 }} variant='outlined'>
      <Box sx={{ paddingX: 4 }}>
        <Grid container justifyContent='space-between' flexWrap='nowrap'>
          <Typography sx={{ marginRight: 2 }} variant='h4'>
            APP Users Information
          </Typography>
          <TextField
            autoComplete='new-password'
            name='search-merlin-users'
            focused
            inputRef={inpuRef}
            sx={{ marginLeft: 2, marginRight: 'auto', width: 350 }}
            size='small'
            defaultValue={search}
            onChange={onChangeSearch}
            label='Search'
            variant='outlined'
            InputProps={{
              endAdornment: (
                <IconButton onClick={onClearSearch} size='small' sx={{ opacity: search ? 1 : 0 }}>
                  <CancelIcon fontSize='small' color='error' />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Box>
      {loading ? (
        <TableSkeleton tHeadHeight={55.5} rowHeight={rowHeight - 1} />
      ) : error ? (
        <Box sx={{ paddingX: 4, paddingTop: 2, paddingBottom: 1 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : data.length ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4 }}>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'email'} onClick={onChangeSort('email')}>
                    <Typography variant='caption' color={sortBy === 'email' ? 'text.primary' : 'text.secondary'}>
                      E-Mail
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'subscriptionPlan'} onClick={onChangeSort('subscriptionPlan')}>
                    <Typography variant='caption' color='text.secondary'>
                      Plan
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'isTester'} onClick={onChangeSort('isTester')}>
                    <Typography variant='caption' color='text.secondary'>
                      Type
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'creationDate'} onClick={onChangeSort('creationDate')}>
                    <Typography variant='caption' color='text.secondary'>
                      Creation Date
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'subscriptionPlanUpdatedDate'} onClick={onChangeSort('subscriptionPlanUpdatedDate')}>
                    <Typography variant='caption' color='text.secondary'>
                      Plan Updated
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'subscriptionPlanExpirationDate'} onClick={onChangeSort('subscriptionPlanExpirationDate')}>
                    <Typography variant='caption' color='text.secondary'>
                      Expiration
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel direction={sortOrder.toLowerCase()} active={sortBy === 'lastLoginDate'} onClick={onChangeSort('lastLoginDate')}>
                    <Typography variant='caption' color='text.secondary'>
                      Last Login
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: 56 }} />
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 540 }}>
              {PageContent}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (rowHeight + 1) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginBottom: 3 }} />
          <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
            <Pagination shape='rounded' page={page} count={totalPages} onChange={onChangePage} size='small' color='primary' />
          </Grid>
        </Fragment>
      ) : (
        <Box sx={{ paddingX: 4, paddingTop: 2, paddingBottom: 1 }}>
          <Alert severity='info'>No Info</Alert>
        </Box>
      )}
    </Card>
  );
};
