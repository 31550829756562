import { createSlice } from '@reduxjs/toolkit';

export const trialRequestsKey = 'trialRequests';
export const topUpHistoryKey = 'topUpHistory';
export const currentUserBalanceKey = 'currentUserBalance';

const initialState = {
  [trialRequestsKey]: 0,
  [topUpHistoryKey]: 0,
  [currentUserBalanceKey]: 0,
};

export const updateObserverSlice = createSlice({
  name: 'updateObserver',
  initialState,
  reducers: {
    incrementKey: (state, { payload }) => {
      state[payload] = state[payload] + 1;
    },
    resetObserverSlice: () => initialState,
  },
});

export const { incrementKey, resetObserverSlice } = updateObserverSlice.actions;

export default updateObserverSlice.reducer;
