import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography, Grid, Button, Alert, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';

import { GeneralInfoSkeleton } from 'components/common/skeletons';
import { AdminApi } from 'api';
import { subscriptionsMap } from 'consts';

export const BaseInfo = ({ loading = true, data = {}, bundles = [], watchlist = [], toggleModal = console.log, updateData = console.log }) => {
  const [approveWait, setApproveWait] = useState(false);
  const {
    palette: {
      text: { green, red },
    },
  } = useTheme();

  const { loading: optionsLoading, error: optionsError } = useSelector((s) => s.subscriptionOptions);

  const getIcon = (value) => (value ? <CheckCircleIcon sx={{ color: green }} /> : <CancelIcon sx={{ color: red }} />);

  if (loading) return <GeneralInfoSkeleton />;

  const {
    email,
    fullName,
    userId,
    lastLoginDate,
    creationDate,
    subscriptionPlan,
    subscriptionPlanUpdatedDate,
    subscriptionPlanExpirationDate,
    wantedToBePro,
    isTester,
    usedAnalyticsProSettings,
    downloadedPortfolio,
    sentTxByEmail,
  } = data;
  const moreThan3WalletsInBundle = bundles.some((b) => b.wallets.length > 3);
  const moreThan1Bundle = bundles.length > 1;

  const onGrantProPlus = () => {
    setApproveWait(true);
    AdminApi.updateMerlinUserSubscription(userId, { subscriptionPlan: 'PRO_PLUS', days: 30, isTester: true })
      .then(() => updateData(userId))
      .finally(() => setApproveWait(false));
  };

  return (
    <Fragment>
      {wantedToBePro ? (
        <Alert
          icon={<InfoIcon fontSize='inherit' />}
          severity='warning'
          sx={{ marginBottom: 3, paddingRight: 5, paddingY: 3 }}
          action={
            subscriptionPlan !== 'PRO_PLUS' ? (
              <Button variant='contained' color='warning' size='small' sx={{ textTransform: 'none' }} onClick={onGrantProPlus} disabled={approveWait}>
                Grant 30-days Trial
              </Button>
            ) : null
          }
        >
          This user interested in Pro+
        </Alert>
      ) : null}
      <Card sx={{ marginBottom: 3, paddingTop: 3, paddingBottom: 4, paddingX: 4 }} variant='outlined'>
        <Grid container sx={{ marginBottom: 1 }} justifyContent='space-between'>
          <Typography variant='h4'>Base Info</Typography>
          <Button variant='contained' size='small' sx={{ textTransform: 'none' }} onClick={toggleModal} disabled={Boolean(optionsLoading || optionsError)}>
            Manage Subscription
          </Button>
        </Grid>
        <Grid sx={{ marginBottom: 2 }} container alignItems='center' justifyContent='space-between'>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              E-mail
            </Typography>
            <Typography variant='body1'>{email || '-'}</Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Full Name
            </Typography>
            <Typography variant='body1'>{fullName || '-'}</Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Plan
            </Typography>
            {subscriptionPlan ? (
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                sx={{ paddingX: 1.5, paddingY: 0.5, width: 'fit-content', height: '23px', borderRadius: 1 }}
                bgcolor={`plans.${subscriptionPlan}`}
              >
                <Typography color={subscriptionPlan === 'FREE' ? 'text.secondary' : 'text.light'} variant='caption'>
                  {subscriptionsMap[subscriptionPlan] || subscriptionPlan}
                </Typography>
              </Grid>
            ) : (
              <Typography variant='body1'>-</Typography>
            )}
          </Grid>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Type
            </Typography>
            <Typography variant='body1'>{isTester ? 'Trial' : 'Client'}</Typography>
          </Grid>
        </Grid>

        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Creation Date
            </Typography>
            <Typography variant='body1'>{moment(creationDate).format('DD.MM.YY , HH:mm')}</Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Plan Updated
            </Typography>
            <Typography variant='body1'>{subscriptionPlanUpdatedDate ? moment(subscriptionPlanUpdatedDate).format('DD.MM.YY , HH:mm') : '-'}</Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Expiration
            </Typography>
            <Typography variant='body1'>
              {subscriptionPlan === 'FREE' || !subscriptionPlanExpirationDate ? '-' : moment(subscriptionPlanExpirationDate).format('DD.MM.YY , HH:mm')}
            </Typography>
          </Grid>
          <Grid flexDirection='column' sx={{ width: '25%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Last Login
            </Typography>
            <Typography variant='body1'>{lastLoginDate ? moment(lastLoginDate).format('DD.MM.YY , HH:mm') : '-'}</Typography>
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ marginBottom: 3, paddingY: 3, paddingX: 4 }} variant='outlined'>
        <Typography sx={{ marginBottom: 1 }} variant='h4'>
          Feature Usage
        </Typography>
        <Typography sx={{ marginBottom: 1 }} variant='body1'>
          Pro
        </Typography>
        <Grid container alignItems='center' justifyContent='flex-start' sx={{ marginBottom: 2 }}>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Analytics Pro Settings
            </Typography>
            {getIcon(usedAnalyticsProSettings)}
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Portfolio downloaded
            </Typography>
            {getIcon(downloadedPortfolio)}
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              Sent TXs by e-mail
            </Typography>
            {getIcon(sentTxByEmail)}
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              More than 3 wall./bundle
            </Typography>
            {getIcon(moreThan3WalletsInBundle)}
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              More than 5 wallets in Watchlist
            </Typography>
            {getIcon(watchlist.length > 5)}
          </Grid>
        </Grid>
        <Typography sx={{ marginBottom: 1 }} variant='body1'>
          Pro Plus
        </Typography>
        <Grid container alignItems='center' justifyContent='flex-start'>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              More than 1 bundle
            </Typography>
            {getIcon(moreThan1Bundle)}
          </Grid>
          <Grid flexDirection='column' sx={{ width: '20%' }}>
            <Typography component='div' sx={{ marginBottom: 1 }} variant='caption' color='text.secondary'>
              More than 10 wallets in Watchlist
            </Typography>
            {getIcon(watchlist.length > 10)}
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
};
