import { useState, Fragment } from 'react';
import { Dialog, TextField, Grow, Alert, Grid, Typography, Card, InputAdornment } from '@mui/material';

import { AdminApi } from 'api';
import { numberWithCommas } from 'helpers';
import { ButtonComponent } from 'components/ui';
import { NumericFormatInput } from 'pages/user/purchaseUnits/formattedInput';

export const CreateInvoiceModal = ({ open, onClose, updateData = console.log, currentUnitsPerDollar, userId = '' }) => {
  const [amount, setAmount] = useState(100);
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const onChangeAmount = ({ target: { value } }) => {
    errorMessage && setErrroMessage('');
    setAmount(value);
  };

  const onSendRequest = async () => {
    setWait(true);
    const res = await AdminApi.sendInvoiceToApiUser({ userId, amount })
      .catch(setErrroMessage)
      .finally(() => setWait(false));
    if (res) {
      updateData();
      setSuccess(true);
    }
  };

  const onEnterPress = (e) => e.key === 'Enter' && onSendRequest();

  const onCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && wait) return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, padding: 4, paddingBottom: 5 }}>
        <Typography mb='12px' variant='h4'>
          Create and Send Invoice
        </Typography>
        <Typography mb='12px' variant='body2' component='p' color='text.secondary'>
          Please enter the value in dollars (integer) and and confirm by clicking on "Send"
        </Typography>
        {errorMessage ? (
          <Grow in={!!errorMessage}>
            <Alert severity='error'>{errorMessage}</Alert>
          </Grow>
        ) : success ? (
          <Grow in={success}>
            <Alert severity='success'>Invoice successfully sent to the user e-mail.</Alert>
          </Grow>
        ) : (
          <Fragment>
            <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              Amount in dollars
            </Typography>
            <TextField
              autoFocus={true}
              variant='outlined'
              fullWidth
              value={+amount}
              onChange={onChangeAmount}
              error={!amount || amount < 1}
              disabled={wait || success}
              onKeyDown={onEnterPress}
              InputProps={{
                sx: { fontSize: 14 },
                inputComponent: NumericFormatInput,
                startAdornment: (
                  <InputAdornment position='start'>
                    <Typography sx={{ lineHeight: '15px' }} variant='body1' component='p' color='text.primary'>
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <Typography sx={{ marginY: '10px', lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              {+amount < 1 ? `Minimum amount is 1 dollar` : `${numberWithCommas(currentUnitsPerDollar * +amount)} units`}
            </Typography>

            <Grid container justifyContent='space-between'>
              <ButtonComponent color='cancelBtn' disabled={wait} onClick={onClose} text='CANCEL' width={76} />
              <ButtonComponent onClick={onSendRequest} disabled={!amount || wait} text='SEND' width={184} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
