import { Fragment } from 'react';

import { EndpointsCallHistory } from 'pages/user/dashboard/endpointsCallHistory';

export const EndpointsCallHistoryTab = ({ userId = '' }) => {
  return (
    <Fragment>
      <EndpointsCallHistory userId={userId} />
    </Fragment>
  );
};
