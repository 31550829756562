import { useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';

import { Login } from 'pages/auth/login';

export const ProtectedRoute = ({ children }) => {
  const { apiKeyCrypted } = useSelector((s) => s.user);

  if (apiKeyCrypted) return children;

  return (
    <Box sx={{ height: '100vh', width: '100vw', backgroundColor: 'background.content' }}>
      <Grid container direction='column' alignItems='center' justifyContent='center' sx={{ width: 822, height: '100vh', margin: '0 auto' }}>
        <Login />
      </Grid>
    </Box>
  );
};
