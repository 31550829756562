import { Fragment, useState } from 'react';
import { Card, Typography, Grid, Box, Alert, IconButton, Tooltip } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import moment from 'moment';

import { ReaponseSkeleton } from 'pages/user/liveApiTest/skeletons';
import { ButtonComponent } from 'components/ui';
import { formatParams, syntaxHighlight } from 'pages/user/liveApiTest/helpers';

export const ResponseBlock = ({ response = null, responseLoading, responseError, setDownloaded, endpoint, paramsState }) => {
  const [jsonCopied, setJsonCopied] = useState(false);

  const createFileName = (extention = 'json') => {
    const formattedParams = formatParams(paramsState);
    let fileName = '';
    fileName += endpoint;
    for (const paramName in formattedParams) {
      fileName += `-${paramName}=${formattedParams[paramName]}`;
    }
    fileName += `-${moment().format('DD.MM.YYYY hh:mm:ss')}.${extention}`;
    return fileName;
  };

  const downloadJSON = (data) => () => {
    const a = document.createElement('a');
    a.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
    a.download = createFileName('json');
    document.body.appendChild(a);
    a.click();
    a.remove();
    setDownloaded(true);
  };

  const onCopyJson = () => {
    navigator.clipboard.writeText(JSON.stringify(response)).then(() => {
      setJsonCopied(true);
      setTimeout(() => {
        setJsonCopied(false);
      }, 1500);
    });
  };

  if (!(response || responseLoading || responseError)) return null;

  return (
    <Card variant='outlined' sx={{ paddingTop: 4, paddingBottom: 5 }}>
      {responseLoading ? (
        <ReaponseSkeleton />
      ) : responseError ? (
        <Box paddingX={4}>
          <Alert severity='error'>{responseError}</Alert>
        </Box>
      ) : (
        <Fragment key='response-content'>
          <Grid container justifyContent='space-between' mb='20px' paddingX={4}>
            <Typography variant='h4'>Response Data</Typography>
            {response ? (
              <Tooltip
                title={
                  <Typography variant='body2' color='text.dark'>
                    Download JSON as File
                  </Typography>
                }
                arrow
                placement='left'
              >
                <Grid>
                  <ButtonComponent
                    width={90}
                    color='inherit'
                    variant='outlined'
                    height={30}
                    text='JSON'
                    endIcon={<DownloadRoundedIcon fontSize='small' />}
                    onClick={downloadJSON(response)}
                  />
                </Grid>
              </Tooltip>
            ) : null}
          </Grid>
          {response ? (
            <Box sx={{ paddingX: 4, position: 'relative' }}>
              <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(response, null, 4)) }} />
              <Tooltip
                title={
                  <Typography variant='body2' color='text.dark'>
                    {jsonCopied ? 'Content Copied' : 'Copy Content'}
                  </Typography>
                }
                arrow
                placement='left'
              >
                <IconButton aria-label='copy' size='small' sx={{ position: 'absolute', top: 10, right: 34 }} color='inherit' onClick={onCopyJson}>
                  {jsonCopied ? <LibraryAddCheckIcon fontSize='inherit' /> : <ContentCopyIcon fontSize='inherit' />}
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box paddingX={4}>
              <Alert severity='info'>No Info</Alert>
            </Box>
          )}
        </Fragment>
      )}
    </Card>
  );
};
