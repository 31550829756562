import { Fragment } from 'react';

import { PricesGrouped } from 'components/common/pricesGrouped';

export const PricingTab = ({ currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading, userId = '' }) => {
  return (
    <Fragment>
      <PricesGrouped {...{ currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading, marginBottom: 0, userId }} />
    </Fragment>
  );
};
