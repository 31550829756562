import { Fragment, useMemo } from 'react';
import { Card, Typography, Grid, Box, TextField, Alert, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Line } from 'react-chartjs-2';

import { ChartSkeleton } from 'components/common/skeletons';
import { Calendar } from 'components/icons';
import { ButtonComponent } from 'components/ui';
import { createChartOptions, createChartData } from 'pages/user/dashboard/helpers';

export const EndpointStatisticChart = ({ data = { labels: [], datasets: [] }, loading, error, from, to, setFrom, setTo, endpointsList = {}, toggleFiltersModal }) => {
  const {
    palette: {
      text: { secondary, primary, linearGrey },
      border: { main: borderColor },
      chart: { blue, blueDark, orange, pink, purple },
    },
  } = useTheme();

  const onChangeDate = (setStateFc) => (nextVal) => setStateFc(nextVal);

  const options = useMemo(() => createChartOptions(secondary, borderColor, primary), [secondary, borderColor, primary]);

  const chartData = useMemo(() => createChartData(data, [blue, blueDark, orange, pink, purple], endpointsList), [data, blue, blueDark, orange, pink, purple, endpointsList]);

  const isEmptyChart = useMemo(() => data.labels.length <= 1, [data]);

  return (
    <Fragment>
      <Card sx={{ paddingTop: 4, marginBottom: '30px' }} variant='outlined'>
        <Grid container justifyContent='space-between' sx={{ paddingX: 4, marginBottom: '26px' }}>
          <Typography variant='h4'>Endpoints Usage over time</Typography>
          <Grid container width='fit-content' alignItems='center' ml='auto'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                inputFormat='DD/MM/YYYY'
                disableFuture
                label='From'
                value={from}
                onChange={onChangeDate(setFrom)}
                renderInput={(params) => <TextField {...params} />}
                components={{ OpenPickerIcon: Calendar }}
                InputProps={{ sx: { color: linearGrey, height: 36, fontSize: 12 } }}
                disabled={loading}
              />
              <Box sx={{ mx: '10px' }} />
              <DatePicker
                inputFormat='DD/MM/YYYY'
                disableFuture
                label='To'
                value={to}
                onChange={onChangeDate(setTo)}
                renderInput={(params) => <TextField {...params} />}
                components={{ OpenPickerIcon: Calendar }}
                InputProps={{ sx: { color: linearGrey, height: 36, fontSize: 12 } }}
                disabled={loading}
              />
            </LocalizationProvider>
          </Grid>
          <ButtonComponent marginLeft='20px' width={95} color='cancelBtn' text='FILTERS' onClick={toggleFiltersModal} disabled={loading || Object.keys(endpointsList).length < 2} />
        </Grid>
        <Box sx={{ paddingBottom: '16px', paddingX: 2 }}>
          <Box sx={{ height: 307, position: 'relative' }}>
            {loading ? (
              <ChartSkeleton chartBlockHeight={269} />
            ) : error ? (
              <Box sx={{ marginX: 2 }}>
                <Alert severity='error'>{error}</Alert>
              </Box>
            ) : isEmptyChart ? (
              <Box sx={{ marginX: 2 }}>
                <Alert severity='info'>No Info by selected period</Alert>
              </Box>
            ) : (
              <Line data={chartData} options={options} />
            )}
          </Box>
        </Box>
      </Card>
    </Fragment>
  );
};
