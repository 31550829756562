import { useState } from 'react';
import { Card, Typography, Grid, useTheme, Dialog, Checkbox, FormControlLabel } from '@mui/material';

import { ButtonComponent } from 'components/ui';
import { CheckBoxIcon, CheckBoxCheckedIcon } from 'components/icons';

export const FiltersModal = ({ open, onClose, endpointsList = {}, setEndpointsList }) => {
  const {
    palette: {
      text: { secondary, black },
    },
  } = useTheme();
  const [list, setList] = useState(endpointsList);

  const onChangeList = (key) => (_, nextVal) => setList({ ...list, [key]: nextVal });

  const onSetFilters = () => {
    setEndpointsList(list);
    onClose();
  };

  const renderList = () =>
    Object.entries(list).map(([key, value]) => (
      <FormControlLabel
        key={key}
        label={<Typography color={value ? black : secondary}>{key}</Typography>}
        control={<Checkbox checked={value} onChange={onChangeList(key)} icon={<CheckBoxIcon />} checkedIcon={<CheckBoxCheckedIcon />} />}
      />
    ));

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 424, padding: 4, paddingBottom: 5 }}>
        <Typography sx={{ marginBottom: 2 }} variant='h4'>
          Filters
        </Typography>
        <Typography sx={{ marginBottom: 3 }} variant='body1' component='p' color='text.secondary'>
          Select Endpoints to show.
        </Typography>
        <Grid container flexDirection='column' mb={3}>
          {renderList()}
        </Grid>
        <Grid container justifyContent='flex-end'>
          <ButtonComponent color='cancelBtn' text='CANCEL' width={120} onClick={onClose} />
          <ButtonComponent onClick={onSetFilters} text='APPLY' marginLeft='20px' />
        </Grid>
      </Card>
    </Dialog>
  );
};
