import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resetApiKeyModalOpen: false,
  myApiKeyModalOpen: false,
  myAccountModalOpen: false,
  unitsLimitModalOpen: false,
};

export const buferSlice = createSlice({
  name: 'bufer',
  initialState,
  reducers: {
    setResetApiKeyModalOpen: (state, { payload }) => {
      state.resetApiKeyModalOpen = payload;
    },
    setMyApiKeyModalOpen: (state, { payload }) => {
      state.myApiKeyModalOpen = payload;
    },
    setMyAccountModalOpen: (state, { payload }) => {
      state.myAccountModalOpen = payload;
    },
    setUnitsLimitModalOpen: (state, { payload }) => {
      state.unitsLimitModalOpen = payload;
    },
    resetBuferSlice: () => initialState,
  },
});

export const { setResetApiKeyModalOpen, setMyApiKeyModalOpen, setMyAccountModalOpen, setUnitsLimitModalOpen, resetBuferSlice } = buferSlice.actions;

export default buferSlice.reducer;
