import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Select Type', 'Select Endpoint', 'Set Params', 'Send Request', 'Download Response'];

export const RequestStepper = ({ activeStep }) => {
  return (
    <Stepper activeStep={activeStep} sx={{ width: '100%', marginBottom: '30px' }}>
      {steps.map((label) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
