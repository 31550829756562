import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, Card, Typography, Grid, Select, MenuItem, Alert } from '@mui/material';

import { AdminApi } from 'api';

export const ManageSubscriptionModal = ({ open, onClose, updateData, userInfo = {} }) => {
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrroMessage] = useState('');

  const { userId, email, subscriptionPlan, isTester } = userInfo;
  const {
    data: { subscriptionPlans, subscriptionPeriods, subscriptionType },
    loading,
  } = useSelector((s) => s.subscriptionOptions);

  const [plan, setPlan] = useState(subscriptionPlan);
  const [period, setPeriod] = useState(30);
  const [isTesterType, setIsTesterType] = useState(isTester);

  const onChangePlan = ({ target: { value } }) => {
    setPlan(value);
    if (value === 'FREE') {
      setPeriod(0);
      setIsTesterType(false);
    } else {
      if (!period) {
        setPeriod(30);
      }
    }
  };
  const onChangePeriod = ({ target: { value } }) => setPeriod(value);
  const onChangeTesterType = ({ target: { value } }) => setIsTesterType(value);

  const onCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && wait) return;
    onClose();
    if (errorMessage) {
      setErrroMessage('');
    }
  };

  const onSubmit = () => {
    setWait(true);
    AdminApi.updateMerlinUserSubscription(userId, { subscriptionPlan: plan, days: period || null, isTester: isTesterType })
      .then(() => {
        updateData();
        onCloseModal();
      })
      .catch((message) => {
        setErrroMessage(message);
        setWait(false);
      });
  };

  const renderOptions = (map = {}) =>
    Object.entries(map).map(([key, val], i) => (
      <MenuItem key={key + val + i} value={val}>
        {key}
      </MenuItem>
    ));

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 600, padding: 4 }}>
        <Typography sx={{ marginBottom: 2 }} variant='h4'>
          Manage Subscription ({email})
        </Typography>
        {errorMessage ? (
          <Alert severity='error'>{errorMessage}</Alert>
        ) : (
          <Fragment>
            <Grid container sx={{ marginBottom: 4 }} justifyContent='space-between'>
              <Grid container direction='column' sx={{ width: '32%' }}>
                <Typography sx={{ marginBottom: 2, fontSize: 12 }} variant='body1' component='p' color='text.secondary'>
                  Subscription Plan
                </Typography>
                <Select value={plan} onChange={onChangePlan} labelId='plan'>
                  {renderOptions(subscriptionPlans)}
                </Select>
              </Grid>
              <Grid container direction='column' sx={{ width: '32%' }}>
                <Typography sx={{ marginBottom: 2, fontSize: 12 }} variant='body1' component='p' color='text.secondary'>
                  Period
                </Typography>
                <Select value={period} disabled={plan === 'FREE'} onChange={onChangePeriod} labelId='plan'>
                  {plan === 'FREE' ? <MenuItem value={0}>Unlimited</MenuItem> : null}
                  {renderOptions(subscriptionPeriods)}
                </Select>
              </Grid>
              <Grid container direction='column' sx={{ width: '32%' }}>
                <Typography sx={{ marginBottom: 2, fontSize: 12 }} variant='body1' component='p' color='text.secondary'>
                  User Type
                </Typography>
                <Select value={isTesterType} disabled={plan === 'FREE'} onChange={onChangeTesterType} labelId='plan'>
                  {renderOptions(subscriptionType)}
                </Select>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Button sx={{ width: '32%', textTransform: 'none', justifyContent: 'center' }} onClick={onClose} disabled={wait} variant='outlined'>
                Cancel
              </Button>
              <Button sx={{ width: '32%', textTransform: 'none', justifyContent: 'center' }} variant='contained' onClick={onSubmit} disabled={wait || loading}>
                Update
              </Button>
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
