import { useState, Fragment, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { Alert, Grow, Card, Typography, CircularProgress } from '@mui/material';

import { paths } from 'consts';
import { resetStore } from 'store/helpers';
import { UserApi } from 'api';

export const Verification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [loading, setLoading] = useState(!!token);
  const [verificationError, setVerificationError] = useState(token ? '' : 'Verification link is invalid.');
  const [successMessage, setSuccessMessage] = useState('');
  const [time, setTime] = useState(15);

  //verification request
  useEffect(() => {
    const verifyAccount = async (token = '') => {
      const result = await UserApi.varifyAccount(token)
        .catch(setVerificationError)
        .finally(() => setLoading(false));
      if (result) {
        setSuccessMessage('Verification successful! Your API key has been sent to your email.');
      }
    };
    if (token) {
      verifyAccount(token);
    }
  }, [token]);

  //run timer
  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setInterval(() => {
        setTime((time) => {
          if (time === 0) {
            clearInterval(timer);
            return 0;
          } else return time - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [successMessage]);

  //redirect on timer end
  useEffect(() => {
    if (!time) {
      resetStore();
      navigate(`/${paths.auth.path}`);
    } // eslint-disable-next-line
  }, [time]);

  return (
    <Fragment>
      <Typography variant='h3' color='text.primary' align='center' sx={{ width: 540, marginBottom: '16px' }}>
        Account Verification
      </Typography>
      <Typography variant='body1' color='text.secondary' align='center' sx={{ width: 555, marginBottom: '32px' }}>
        Complete sign up to access MERLIN API Client Panel
      </Typography>
      <Card variant='outlined' sx={{ width: 555, minHeight: 156, padding: '30px 30px 40px' }}>
        {loading ? (
          <Fragment>
            <CircularProgress size={48} sx={{ margin: 'auto', display: 'block' }} />
            <Typography mt={3} align='center'>
              We verify your account...
            </Typography>
          </Fragment>
        ) : verificationError ? (
          <Fragment>
            <Grow in={!!verificationError}>
              <Alert sx={{ width: '100%', justifyContent: 'center' }} severity='error'>
                {verificationError}
              </Alert>
            </Grow>
            <Link className='back-link' to={`/${paths.auth.path}`}>
              <Typography color='primary.main' align='center' mt={3}>
                Go to Home Page
              </Typography>
            </Link>
          </Fragment>
        ) : successMessage ? (
          <Fragment>
            <Grow in={!!successMessage}>
              <Alert sx={{ width: '100%', justifyContent: 'center' }} severity='success'>
                {successMessage}
              </Alert>
            </Grow>
            <Typography mt={3} align='center'>
              You will be automatically redirected to the Home Page after {time} seconds.
            </Typography>
          </Fragment>
        ) : null}
      </Card>
    </Fragment>
  );
};
