import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import { UnitsUsageChart } from 'pages/user/dashboard/unitsUsageChart';
import { UnitsUsageTable } from 'pages/user/dashboard/unitsUsageTable';

import { UserApi, AdminApi } from 'api';

export const UnitsUsageTab = ({ userId }) => {
  const [from, setFrom] = useState(moment().subtract(7, 'days').unix() * 1000);
  const [to, setTo] = useState(moment.now());

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (from && to) {
      const now = moment();
      const endDays = now.diff(to, 'days');
      const startDays = now.diff(from, 'days');

      if (!isNaN(startDays) && !isNaN(endDays) && startDays > endDays) {
        setLoading(true);
        Promise.all(
          userId
            ? [AdminApi.getUserUnitsUsageChart({ userId, startDays, endDays }), AdminApi.getUserUnitsUsageTable({ userId, startDays, endDays })]
            : [UserApi.getUnitsUsageChart({ startDays, endDays }), UserApi.getUnitsUsageTable({ startDays, endDays })]
        )
          .then(([resChart, resTable]) => {
            const _chartData = { ...resChart.plots['CREDITS USAGE'] };
            _chartData.datasets[0].label = 'Units Usage';
            setChartData(_chartData);
            setTableData(resTable);
          })
          .catch(setError)
          .finally(() => setLoading(false));
      }
    }
  }, [from, to, userId]);

  return (
    <Fragment>
      <UnitsUsageChart {...{ data: chartData, loading, error, from, to, setFrom, setTo }} />
      <UnitsUsageTable {...{ data: tableData, loading, error, from, to }} />
    </Fragment>
  );
};
