import { useMemo, useState, useEffect, Fragment } from 'react';
import { Card, Typography, Grid, Box, TextField, Alert, Table, TableBody, TableCell, TableHead, TableRow, Pagination, Tooltip, MenuItem, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { TableSkeleton } from 'components/common/skeletons';
import { Calendar, Info } from 'components/icons';
import { CustomSelect } from 'components/ui/selectPlaceholder/customSelect';
import { UserApi, AdminApi } from 'api';
import { numberWithCommas } from 'helpers';
import { pageSize as portion } from 'pages/user/dashboard/const';

export const EndpointsCallHistory = ({ userId = '' }) => {
  const groupsList = useSelector((s) => s.userPrices.data.groupsList);
  const {
    palette: {
      text: { linearGrey },
    },
  } = useTheme();

  const [from, setFrom] = useState(moment().subtract(7, 'days').unix() * 1000);
  const [to, setTo] = useState(moment.now());

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);

  const [page, setPage] = useState(0);
  const pagesCount = useMemo(() => Math.ceil(data.length / portion), [data]);

  const emptyRows = Math.max(0, (1 + page) * portion - data.length);

  const onChangePage = (_, nextPage) => setPage(nextPage - 1);

  const onChangeDate = (setStateFc) => (nextVal) => setStateFc(nextVal);

  const onChangeGroups = (event) => {
    const {
      target: { value },
    } = event;

    setGroups(typeof value === 'string' ? value.split(',') : value);
  };

  const PageContent = useMemo(() => {
    const startIndex = page * portion;
    const endIndex = startIndex + portion;

    return data.slice(startIndex, endIndex).map(({ day, endpoint, amount, trial, cost, route }, index) => (
      <TableRow sx={{ height: 53 }} key={'row-' + index + Math.random()}>
        <TableCell {...{ component: 'th', scope: 'row', sx: { paddingLeft: 4 } }}>
          <Typography variant='body1' color='text.dark'>
            {moment(day).format('DD.MM.YYYY, dddd')}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Tooltip
            title={
              <Fragment>
                <Typography variant='body1'>{route}</Typography>
              </Fragment>
            }
            arrow
            placement='top'
          >
            <Grid container alignItems='center' sx={{ width: 'fit-content', cursor: 'pointer' }}>
              <Typography variant='body1' color='text.dark' sx={{ marginRight: 1 }}>
                {endpoint}
              </Typography>
              <Info />
            </Grid>
          </Tooltip>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.linearGrey'>
            {numberWithCommas(amount) || '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1' color='text.linearGrey'>
            {numberWithCommas(trial) || '-'}
          </Typography>
        </TableCell>
        <TableCell {...{ component: 'th', scope: 'row' }}>
          <Typography variant='body1'>{cost ? `$${numberWithCommas(+cost.toFixed(2))}` : '-'} </Typography>
        </TableCell>
      </TableRow>
    ));
  }, [data, page]);

  const renderEndpointGroups = () =>
    groupsList.map((el) => (
      <MenuItem value={el} key={el} disabled={loading}>
        {el}
      </MenuItem>
    ));

  useEffect(() => {
    if (from && to) {
      const now = moment();
      const endDays = now.diff(to, 'days');
      const startDays = now.diff(from, 'days');

      if (!isNaN(startDays) && !isNaN(endDays) && startDays > endDays) {
        setLoading(true);
        (userId
          ? AdminApi.getUserEndpointsCallHistory({ userId, startDays, endDays, groups: !groups.length || groups.length === groupsList.length ? null : groups.join(',') })
          : UserApi.getEndpointsCallHistory({ startDays, endDays, groups: !groups.length || groups.length === groupsList.length ? null : groups.join(',') })
        )
          .then((res) => {
            setData(res);
            setError('');
          })
          .catch(setError)
          .finally(() => setLoading(false));
      }
    } // eslint-disable-next-line
  }, [from, to, groups, userId]);

  return (
    <Card sx={{ paddingY: 4 }} variant='outlined'>
      <Grid container justifyContent='space-between' sx={{ paddingX: 4 }}>
        <Typography variant='h4'>Endpoints Call History</Typography>
        <Grid container width='fit-content' alignItems='center' ml='auto' mr='20px'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              inputFormat='DD/MM/YYYY'
              disableFuture
              label='From'
              value={from}
              onChange={onChangeDate(setFrom)}
              renderInput={(params) => <TextField {...params} />}
              components={{ OpenPickerIcon: Calendar }}
              InputProps={{ sx: { color: linearGrey, height: 36, fontSize: 12 } }}
              disabled={loading}
            />
            <Box sx={{ mx: '10px' }} />
            <DatePicker
              inputFormat='DD/MM/YYYY'
              disableFuture
              label='To'
              value={to}
              onChange={onChangeDate(setTo)}
              renderInput={(params) => <TextField {...params} />}
              components={{ OpenPickerIcon: Calendar }}
              InputProps={{ sx: { color: linearGrey, height: 36, fontSize: 12 } }}
              disabled={loading}
            />
          </LocalizationProvider>
        </Grid>
        <CustomSelect
          {...{
            multiple: true,
            value: groups,
            disabled: loading || !groupsList.length,
            onChange: onChangeGroups,
            name: 'endpoint-type',
            placeholder: 'All Endpoints',
            showPlaceholder: !groups.length,
            containerWidth: 236,
            color: linearGrey,
          }}
        >
          {renderEndpointGroups()}
        </CustomSelect>
      </Grid>
      {loading ? (
        <TableSkeleton />
      ) : error ? (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='error'>{error}</Alert>
        </Box>
      ) : !data.length ? (
        <Box sx={{ padding: 4, paddingBottom: 0 }}>
          <Alert severity='info'>No Info</Alert>
        </Box>
      ) : (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 4 }}>
                  <Typography variant='caption' color='text.secondary'>
                    DATE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    ENDPOINT
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    UNITS USED
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    TRIAL UNITS USED
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='caption' color='text.secondary'>
                    COST ($)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: 540 }}>
              {PageContent}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 54 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} sx={{ borderColor: 'transparent' }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Box sx={{ marginBottom: 3 }} />
          <Grid container justifyContent='flex-end' sx={{ paddingRight: 4 }}>
            <Pagination shape='rounded' count={pagesCount} onChange={onChangePage} size='small' color='primary' />
          </Grid>
        </Fragment>
      )}
    </Card>
  );
};
