import { Fragment, useState } from 'react';
import { Dialog, Card, Typography, Grid, TextField } from '@mui/material';
import moment from 'moment';

import { NumericFormatInput } from 'pages/user/purchaseUnits/formattedInput';
import { ButtonComponent } from 'components/ui';

export const ConfirmTrialModal = ({ open, onClose, confirmData, onSetOrderStatus }) => {
  const [units, setUnits] = useState(100);

  const onChangeUnits = ({ target: { value } }) => {
    setUnits(+value);
  };

  if (!confirmData) return null;
  const { email, date, id, userId, isApprove } = confirmData || {};

  const onSubmit = () => {
    onSetOrderStatus(email, date, id, userId, units, isApprove)();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, padding: 4, paddingBottom: 5 }}>
        <Typography sx={{ marginBottom: 2 }} variant='h4'>
          {isApprove ? 'Approve' : 'Reject'} Trial Units Request
        </Typography>
        <Typography sx={{ marginBottom: isApprove ? 2 : 4 }} mb='20px' variant='body2' component='p' color='text.secondary'>
          Are you sure to <strong>{isApprove ? 'approve' : 'reject'}</strong> request from{' '}
          <strong>
            {email} ({moment(date).format('DD.MM.YYYY hh:mm A')})
          </strong>
          ?
        </Typography>
        {isApprove ? (
          <Fragment>
            <Typography mb='10px' sx={{ lineHeight: '15px' }} variant='body2' component='p' color='text.secondary'>
              Amount of Units
            </Typography>
            <TextField
              autoFocus={true}
              variant='outlined'
              fullWidth
              value={units}
              onChange={onChangeUnits}
              error={!units || units < 0}
              sx={{ marginBottom: 4 }}
              InputProps={{ sx: { fontSize: 14 }, inputComponent: NumericFormatInput }}
            />
          </Fragment>
        ) : null}
        <Grid container justifyContent='space-between'>
          <ButtonComponent color='cancelBtn' onClick={onClose} text='CANCEL' width={76} />
          <ButtonComponent
            onClick={onSubmit}
            disabled={isApprove ? !units || units < 0 : false}
            color={isApprove ? 'primary' : 'error'}
            text={isApprove ? 'APPROVE' : 'REJECT'}
            width={184}
          />
        </Grid>
      </Card>
    </Dialog>
  );
};
