import { useState } from 'react';
import { Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

export const RouteRow = ({ route = '', address = '12345' }) => {
  const [routeCopied, setRouteCopied] = useState(false);

  const onCopyRoute = () => {
    let _route = route;
    if ((route.includes('{address}') || route.includes('{userAddress}')) && address) {
      _route = _route.replace('{address}', address).replace('{userAddress}', address);
    }
    navigator.clipboard.writeText(_route).then(() => {
      setRouteCopied(true);
      setTimeout(() => {
        setRouteCopied(false);
      }, 1500);
    });
  };

  return (
    <Typography color='text.secondary' mb={1} position='relative'>
      Route:{' '}
      <Typography color='text.dark' component='span' mr={1}>
        {route}
      </Typography>
      <Tooltip
        title={
          <Typography variant='body2' color='text.dark'>
            {routeCopied ? 'Route Copied' : 'Copy Route'}
          </Typography>
        }
        arrow
        placement='right'
      >
        <IconButton size='small' color='inherit' sx={{ position: 'absolute', top: -5 }} onClick={onCopyRoute}>
          {routeCopied ? <LibraryAddCheckIcon fontSize='inherit' /> : <ContentCopyIcon fontSize='inherit' />}
        </IconButton>
      </Tooltip>
    </Typography>
  );
};
