import { createSlice } from "@reduxjs/toolkit";

import { loginLocalStorageName } from "consts";

const initState = () => {
  const loginData = localStorage.getItem(loginLocalStorageName);
  const initialState = {
    apiKeyCrypted: "",
    role: "",
  };
  if (loginData) {
    try {
      const { role, apiKeyCrypted } = JSON.parse(loginData);
      initialState.role = role;
      initialState.apiKeyCrypted = apiKeyCrypted;
    } catch (err) {
      console.log(err);
    }
  }
  return initialState;
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState(),
  reducers: {
    login: (state, { payload: { role, apiKeyCrypted } }) => {
      state.role = role;
      state.apiKeyCrypted = apiKeyCrypted;
    },
    logout: (state) => {
      state.apiKeyCrypted = "";
      state.role = "";
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
