import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthPanel } from 'panels/auth.panel';
import { UserPanel } from 'panels/user.panel';
import { AdminPanel } from 'panels/admin.panel';
import { NotFound } from 'pages/common/404';

import { useHubspot } from 'helpers/hooks';
import { paths } from 'consts';

const App = () => {
  useHubspot();

  return (
    <Routes>
      <Route path={`/${paths.admin.path}/${paths.any.path}`} element={<AdminPanel />} />
      <Route path={`/${paths.user.path}/${paths.any.path}`} element={<UserPanel />} />
      <Route path={`/${paths.auth.path}/${paths.any.path}`} element={<AuthPanel />} />
      <Route path={paths.empty.path} element={<Navigate to={`/${paths.auth.path}`} />} />
      <Route path={paths.any.path} element={<NotFound />} />
    </Routes>
  );
};

export default App;
