import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ADMIN_ROLES, USER_ROLES, paths } from 'consts';

export const PublicRoute = ({ children }) => {
  const { apiKeyCrypted, role } = useSelector((s) => s.user);

  if (!apiKeyCrypted || window.location.pathname === `/${paths.auth.path}/${paths.varification.path}`) return children;

  return (
    <Navigate
      to={USER_ROLES.includes(role) ? `/${paths.user.path}/${paths.dashboard.path}` : ADMIN_ROLES.includes(role) ? `/${paths.admin.path}/${paths.users.path}` : paths.empty.path}
    />
  );
};
