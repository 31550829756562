import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import { EndpointStatisticChart } from 'pages/user/dashboard/endpointStatisticsChart';
import { EndpointsConsumption } from 'pages/user/dashboard/endpointsConsumption';
import { FiltersModal } from 'components/common/chartFilterModal';
import { UserApi, AdminApi } from 'api';

export const EnpointStatisticTab = ({ currentUnitsPerDollar, currentUnitsPerDollarError, userId = '' }) => {
  const [from, setFrom] = useState(moment().subtract(7, 'days').unix() * 1000);
  const [to, setTo] = useState(moment.now());

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [endpointsList, setEndpointsList] = useState({}); //for filtering
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const toggleFiltersModal = () => setFiltersOpen(!filtersOpen);

  useEffect(() => {
    if (from && to) {
      const now = moment();
      const endDays = now.diff(to, 'days');
      const startDays = now.diff(from, 'days');

      if (!isNaN(startDays) && !isNaN(endDays) && startDays > endDays) {
        setLoading(true);
        (userId ? AdminApi.getUserEndpointStatisticChart({ userId, startDays, endDays }) : UserApi.getEndpointStatisticChart({ startDays, endDays }))
          .then((res) => {
            const _endpointsList = {};
            if (res.plots['ENDPOINTS USAGE']) {
              res.plots['ENDPOINTS USAGE'].datasets.forEach((ds) => {
                _endpointsList[ds.label] = true;
              });
            }
            setChartData(res.plots['ENDPOINTS USAGE']);
            setError('');
            setEndpointsList(_endpointsList);
          })
          .catch((e) => {
            setError(e);
            setEndpointsList({});
          })
          .finally(() => setLoading(false));
      }
    }
  }, [from, to, userId]);

  return (
    <Fragment>
      <EndpointStatisticChart {...{ data: chartData, loading, error, from, to, setFrom, setTo, endpointsList, toggleFiltersModal }} />
      <EndpointsConsumption {...{ loading, error, chartData, endpointsList, from, to, currentUnitsPerDollar, currentUnitsPerDollarError, userId }} />
      <FiltersModal {...{ key: String(filtersOpen), open: filtersOpen, onClose: toggleFiltersModal, endpointsList, setEndpointsList }} />
    </Fragment>
  );
};
