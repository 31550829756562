import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, TextField, Alert, Grid, Typography, Card, CircularProgress, Select, MenuItem, Checkbox, useTheme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { Copy, CheckBoxIcon, CheckBoxCheckedIcon } from 'components/icons';
import { NumericFormatInput } from 'pages/user/purchaseUnits/formattedInput';
import { ButtonComponent } from 'components/ui';
import { ClickableItem } from 'pages/admin/dashboard/addUser';
import { UserApi, AdminApi } from 'api';
import { login } from 'store/slices/user';
import { setAccessKey, setUnitsLimit } from 'store/slices/userBaseInfo';
import { setResetApiKeyModalOpen, setMyApiKeyModalOpen, setMyAccountModalOpen, setUnitsLimitModalOpen } from 'store/slices/bufer';
import { loginLocalStorageName } from 'consts';

export const ResetApiKeyModal = () => {
  const dispatch = useDispatch();
  const { role } = useSelector((s) => s.user);
  const { resetApiKeyModalOpen } = useSelector((s) => s.bufer);
  const [loading, setLoading] = useState(false);
  const [responseErrror, setResponseError] = useState('');
  const [resposeSuccess, setResponseSuccess] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    UserApi.changeApiKey()
      .then((res) => {
        try {
          const { first, second } = res;
          if (first && second) {
            const newLoginData = { role, apiKeyCrypted: first };
            dispatch(login(newLoginData));
            dispatch(setAccessKey(second));
            setResponseSuccess('The API key has been successfully changed. Your new key has also been sent to your email.');
            localStorage.setItem(loginLocalStorageName, JSON.stringify(newLoginData));
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch(setResponseError)
      .finally(() => setLoading(false));
  };

  const onClose = () => dispatch(setResetApiKeyModalOpen(false));

  const onCloseModal = (_, reason) => {
    if (loading && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={resetApiKeyModalOpen} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, paddingX: 4, paddingTop: 4, paddingBottom: resposeSuccess || responseErrror ? 4 : 5 }}>
        <Typography mb='12px' sx={{ marginBottom: 2 }} variant='h4'>
          Reset API Key
        </Typography>
        {responseErrror ? (
          <Alert severity='error'>{responseErrror}</Alert>
        ) : resposeSuccess ? (
          <Alert security='success'>{resposeSuccess}</Alert>
        ) : (
          <Fragment key='content'>
            <Typography mb='20px' variant='body2' component='p' color='text.secondary'>
              Your API Key will be changed. Please make sure to update your system with the new key. Proceed with the API Key reset?
            </Typography>
            <Grid container justifyContent='space-between'>
              <ButtonComponent color='cancelBtn' disabled={loading} onClick={onClose} text='CANCEL' width={76} />
              <ButtonComponent color='error' disabled={loading} onClick={onSubmit} text='RESET KEY' width={184} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};

export const MyApiKeyModal = () => {
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const accessKey = useSelector((s) => s.userBaseInfo.accessKey);
  const myApiKeyModalOpen = useSelector((s) => s.bufer.myApiKeyModalOpen);

  const onClose = () => dispatch(setMyApiKeyModalOpen(false));

  const onCopyKey = () => navigator.clipboard.writeText(accessKey).then(() => setCopied(true));

  const onResetApiKey = () => {
    onClose();
    setTimeout(() => {
      dispatch(setResetApiKeyModalOpen(true));
      //appear next modal transition
    }, 225);
  };

  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [copied]);

  return (
    <Dialog open={myApiKeyModalOpen} onClose={onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 540, padding: 4, paddingBottom: '17px' }}>
        <Typography mb='12px' sx={{ marginBottom: 2 }} variant='h4'>
          My API Key
        </Typography>
        <Typography variant='body2' mb={4} color='text.secondary'>
          Copy or reset your API Key
        </Typography>
        <Typography variant='body2' mb='10px' color='text.secondary'>
          API Key
        </Typography>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          sx={{ height: 46, borderRadius: 1, cursor: 'pointer', bgcolor: '#F2F8FF', padding: '6px 14px', marginBottom: '18px' }}
          onClick={onCopyKey}
        >
          <Typography variant='body1' maxWidth='calc(100% - 20px)' noWrap>
            {copied ? 'API key copied to clipboard' : accessKey}
          </Typography>
          <Copy />
        </Grid>
        <Typography variant='body2' mb='40px' color='text.secondary'>
          You can{' '}
          <Typography variant='body2' component='span' color='text.red' sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={onResetApiKey}>
            Reset your API Key
          </Typography>
          , keep in mind you will need to update it in your system as well
        </Typography>
        <Grid container justifyContent='flex-end'>
          <ButtonComponent text='CLOSE' color='cancelBtn' width={120} onClick={onClose} />
        </Grid>
      </Card>
    </Dialog>
  );
};

export const MyAccountModal = () => {
  const {
    palette: {
      text: { primary: black, secondary: grey },
      border: { main: borderColor },
    },
  } = useTheme();
  const dispatch = useDispatch();
  const myAccountModalOpen = useSelector((s) => s.bufer.myAccountModalOpen);
  const {
    data: { product, type: typesOptions },
    loading: optionsLoading,
    error: optionsError,
  } = useSelector((s) => s.registrationOptions);
  const [accountDetails, setAccountDetails] = useState({ companyName: '', products: [], type: '', email: '' });
  const [accountDetailsLoading, setAccountDetailsLoading] = useState(true);
  const [accountDetailsError, setAccountDetailsError] = useState('');
  const [updated, setUpdated] = useState(false);
  const [wait, setWait] = useState(false);

  const { firstName, lastName, companyName, products, type } = accountDetails;
  const loading = optionsLoading || accountDetailsLoading;
  const error = optionsError || accountDetailsError;

  const onClose = () => dispatch(setMyAccountModalOpen(false));

  const onCloseModal = (_, reason) => {
    if (wait && reason === 'backdropClick') return;
    onClose();
  };

  useEffect(() => {
    if (myAccountModalOpen) {
      const abortController = new AbortController();
      UserApi.getAccountDetails(abortController.signal)
        .then(({ firstName, lastName, companyName, products, type, email }) =>
          setAccountDetails({ firstName: firstName || '', lastName: lastName || '', companyName: companyName || '', products, type: type || '', email })
        )
        .catch(setAccountDetailsError)
        .finally(() => setAccountDetailsLoading(false));

      return () => abortController.abort();
    }
  }, [myAccountModalOpen]);

  const renderTypes = () =>
    Object.entries(typesOptions).map(([key, val]) => (
      <MenuItem value={key} key={key}>
        {val}
      </MenuItem>
    ));

  const renderProducts = () =>
    Object.entries(product).map(([key, val]) => {
      const isActive = products.includes(key);
      return (
        <ClickableItem {...{ key, isActive, onClick: wait ? undefined : onChangeProducts(key), borderColor, paddingRight: '7px', width: 'calc(50% - 10px)' }}>
          <Typography variant='body1' color={isActive ? black : grey}>
            {val}
          </Typography>
          <Checkbox checked={isActive} value={key} icon={<CheckBoxIcon />} checkedIcon={<CheckBoxCheckedIcon />} name='env-radio-buttons' inputProps={{ 'aria-label': key }} />
        </ClickableItem>
      );
    });

  const onChangeField = ({ target: { value, name } }) => setAccountDetails((prev) => ({ ...prev, [name]: value }));

  const onChangeProducts = (p) => () => {
    const _products = [...products];
    const index = _products.indexOf(p);
    if (index !== -1) _products.splice(index, 1);
    else _products.push(p);
    setAccountDetails((prev) => ({ ...prev, products: _products }));
  };

  const onSubmit = () => {
    setWait(true);
    UserApi.updateAccountDetails(accountDetails)
      .then(() => setUpdated(true))
      .catch(setAccountDetailsError)
      .finally(() => setWait(false));
  };

  return (
    <Dialog open={myAccountModalOpen} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 540, padding: 4, paddingBottom: 5 }}>
        <Typography mb='12px' variant='h4'>
          About Me
        </Typography>
        <Typography mb={4} variant='body2' color='text.secondary'>
          We would like to know you better, please fill in the following details
        </Typography>
        <Grid container>
          {loading ? (
            <CircularProgress sx={{ margin: 'auto' }} />
          ) : error ? (
            <Alert sx={{ width: '100%' }} severity='error'>
              {error}
            </Alert>
          ) : updated ? (
            <Alert sx={{ width: '100%' }} severity='success'>
              Account details successfully updated
            </Alert>
          ) : (
            <Fragment key='form'>
              <Grid container justifyContent='space-between' mb='20px'>
                <Grid container direction='column' width='calc(50% - 10px)'>
                  <Typography mb='10px' variant='body2' color='text.secondary'>
                    First Name
                  </Typography>
                  <TextField
                    InputProps={{ sx: { fontSize: 14 } }}
                    name='firstName'
                    variant='outlined'
                    placeholder='Your First Name'
                    fullWidth
                    value={firstName || ''}
                    onChange={onChangeField}
                    disabled={wait}
                  />
                </Grid>
                <Grid container direction='column' width='calc(50% - 10px)'>
                  <Typography mb='10px' variant='body2' color='text.secondary'>
                    Last Name
                  </Typography>
                  <TextField
                    InputProps={{ sx: { fontSize: 14 } }}
                    name='lastName'
                    variant='outlined'
                    placeholder='Your Last Name'
                    fullWidth
                    value={lastName || ''}
                    onChange={onChangeField}
                    disabled={wait}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent='space-between' mb='20px'>
                <Grid container direction='column' width='calc(50% - 10px)'>
                  <Typography mb='10px' variant='body2' color='text.secondary'>
                    Company Name
                  </Typography>
                  <TextField
                    InputProps={{ sx: { fontSize: 14 } }}
                    name='companyName'
                    variant='outlined'
                    placeholder='Your company name'
                    fullWidth
                    value={companyName || ''}
                    onChange={onChangeField}
                    disabled={wait}
                  />
                </Grid>
                <Grid container direction='column' width='calc(50% - 10px)'>
                  <Typography mb='10px' variant='body2' color='text.secondary'>
                    Activity
                  </Typography>
                  <Select fullWidth value={type} disabled={wait} onChange={onChangeField} name='type' IconComponent={ExpandMoreRoundedIcon} sx={{ fontSize: 14, height: 46 }}>
                    {renderTypes()}
                  </Select>
                </Grid>
              </Grid>
              <Grid container direction='column' mb='20px'>
                <Typography mb='10px' variant='body2' color='text.secondary'>
                  My Merlin Api Product
                </Typography>
                <Grid container justifyContent='space-between'>
                  {renderProducts()}
                </Grid>
              </Grid>
              <Grid container justifyContent='flex-end'>
                <ButtonComponent color='cancelBtn' disabled={wait} onClick={onClose} text='CANCEL' width={120} marginRight='20px' />
                <ButtonComponent disabled={wait} onClick={onSubmit} text='SAVE CHANGES' />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Card>
    </Dialog>
  );
};

export const ResetUserApiKeyModal = ({ open = false, onClose = console.log, email = '', userId = '', updateData }) => {
  const [loading, setLoading] = useState(false);
  const [responseErrror, setResponseError] = useState('');
  const [resposeSuccess, setResponseSuccess] = useState('');

  const onSubmit = () => {
    setLoading(true);
    AdminApi.changeUserApiKey(userId)
      .then(() => {
        setResponseSuccess('The API key has been successfully changed. New key has also been sent to user email.');
        updateData();
      })
      .catch(setResponseError)
      .finally(() => setLoading(false));
  };

  const onCloseModal = (_, reason) => {
    if (loading && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, paddingX: 4, paddingTop: 4, paddingBottom: resposeSuccess || responseErrror ? 4 : 5 }}>
        <Typography mb='12px' sx={{ marginBottom: 2 }} variant='h4'>
          Reset API Key
        </Typography>
        {responseErrror ? (
          <Alert severity='error'>{responseErrror}</Alert>
        ) : resposeSuccess ? (
          <Alert security='success'>{resposeSuccess}</Alert>
        ) : (
          <Fragment key='content'>
            <Typography mb='20px' variant='body2' component='p' color='text.secondary'>
              User{' '}
              <Typography variant='body2' component='span' color='text.primary'>
                ({email})
              </Typography>{' '}
              API Key will be changed. Please make sure to update system with the new key. Proceed with the API Key reset?
            </Typography>
            <Grid container justifyContent='space-between'>
              <ButtonComponent color='cancelBtn' disabled={loading} onClick={onClose} text='CANCEL' width={76} />
              <ButtonComponent color='error' disabled={loading} onClick={onSubmit} text='RESET KEY' width={184} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};

export const UnitsLimitModal = () => {
  const {
    palette: {
      primary: { main: mainBlue },
      border: { main: borderColor },
      text: { primary: blackText },
      error: { main: red },
    },
  } = useTheme();
  const dispatch = useDispatch();
  const unitsLimitModalOpen = useSelector((s) => s.bufer.unitsLimitModalOpen);
  const { email, unitsLimit: prevUnitsLimit, error: userInfoError } = useSelector((s) => s.userBaseInfo);
  const [error, setError] = useState(userInfoError);
  const [updated, setUpdated] = useState(false);
  const [wait, setWait] = useState(false);
  const [nextUnitsLimit, setNextUnitsLimit] = useState(prevUnitsLimit);
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);

  const onChangeInputFocused = (nextVal) => () => setInputFocused(nextVal);
  const onChangeInputHovered = (nextVal) => () => setInputHovered(nextVal);

  const limitError = nextUnitsLimit < 1000 || !Number.isInteger(+nextUnitsLimit);
  const isChanged = nextUnitsLimit !== prevUnitsLimit;

  const onChangeLimit = ({ target: { value } }) => {
    setNextUnitsLimit(value);
  };

  const onClose = () => dispatch(setUnitsLimitModalOpen(false));

  const onCloseModal = (_, reason) => {
    if (wait && reason === 'backdropClick') return;
    onClose();
  };

  const onSubmit = () => {
    if (isChanged) {
      setWait(true);
      UserApi.updateAccountDetails({ email, unitsLimit: nextUnitsLimit })
        .then(() => {
          setUpdated(true);
          if (isChanged) {
            dispatch(setUnitsLimit(+nextUnitsLimit));
          }
        })
        .catch(setError)
        .finally(() => setWait(false));
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={unitsLimitModalOpen} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, padding: 4, paddingBottom: 5 }}>
        <Typography mb='12px' variant='h4'>
          My Low Balance Notification
        </Typography>
        <Typography mb={4} variant='body2' color='text.secondary'>
          Please specify the minimum balance at which you’d like to receive the automatic email notification
        </Typography>
        <Grid container>
          {updated ? (
            <Alert sx={{ width: '100%' }} severity='success'>
              Low Balance Notification successfully updated
            </Alert>
          ) : error ? (
            <Alert sx={{ width: '100%' }} severity='error'>
              {error}
            </Alert>
          ) : (
            <Fragment key='form'>
              <Grid sx={{ width: '100%', height: 42, marginBottom: '20px', position: 'relative' }}>
                <TextField
                  fullWidth
                  sx={{ height: 42 }}
                  value={nextUnitsLimit}
                  onChange={onChangeLimit}
                  error={limitError}
                  disabled={wait}
                  onFocus={onChangeInputFocused(true)}
                  onBlur={onChangeInputFocused(false)}
                  onMouseEnter={onChangeInputHovered(true)}
                  onMouseLeave={onChangeInputHovered(false)}
                  InputProps={{ sx: { paddingLeft: '4px', paddingRight: 13.5, height: 42 }, inputComponent: NumericFormatInput }}
                />
                <Grid
                  container
                  sx={{
                    width: 77,
                    height: '100%',
                    borderLeft: `1px solid ${limitError ? red : inputFocused ? mainBlue : inputHovered ? blackText : borderColor}`,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }}
                >
                  <Typography variant='subtitle1' color='text.secondary' m='auto'>
                    units
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <ButtonComponent color='cancelBtn' disabled={wait} onClick={onClose} text='CANCEL' width={76} />
                <ButtonComponent disabled={wait || limitError} onClick={onSubmit} text='SAVE CHANGES' width={184} />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Card>
    </Dialog>
  );
};
