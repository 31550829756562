import axios from 'axios';

import { store } from 'store';
import { resetStore } from 'store/helpers';

const baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({ baseURL });
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {
      if (error.response.request.responseURL !== `${baseURL}/public/login`) {
        resetStore();
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default class Api {
  static publicPath = '/public';

  static adminPath = '/admin/public';

  static adminMerlinPath = '/admin';

  static apiUserPath = '/api-user';

  static axiosGet = (url = '', params = {}, signal = null) =>
    new Promise(async (res, rej) => {
      const response = await instance
        .get(url, { headers: { Authorization: store.getState().user.apiKeyCrypted }, params, signal })
        .catch((e) => rej(e.response?.data && typeof e.response?.data === 'string' ? e.response?.data : e.message));
      if (response && response.status === 200) res(await response.data);
    });

  static axiosPost = (url = '', body = null, headers = {}) =>
    new Promise(async (res, rej) => {
      const response = await instance
        .post(url, body, { headers: { Authorization: store.getState().user.apiKeyCrypted, ...headers } })
        .catch((e) => rej(e.response?.data && typeof e.response?.data === 'string' ? e.response?.data : e.message));
      if (response && response.status === 200) res((await response.data) || true);
    });

  static axiosPut = (url = '', body = null, params = {}) =>
    new Promise(async (res, rej) => {
      const response = await instance
        .put(url, body, { headers: { Authorization: store.getState().user.apiKeyCrypted }, params })
        .catch((e) => rej(e.response?.data && typeof e.response?.data === 'string' ? e.response?.data : e.message));
      if (response && response.status === 200) res((await response.data) || true);
    });

  static axiosDelete = (url = '') =>
    new Promise(async (res, rej) => {
      const response = await instance
        .delete(url, { headers: { Authorization: store.getState().user.apiKeyCrypted } })
        .catch((e) => rej(e.response?.data && typeof e.response?.data === 'string' ? e.response?.data : e.message));
      if (response && response.status === 200) res((await response.data) || true);
    });

  static axiosGetFile = (url = '') =>
    new Promise(async (res, rej) => {
      const response = await instance
        .get(url, { headers: { Authorization: store.getState().user.apiKeyCrypted }, responseType: 'blob' })
        .catch((e) => rej(e.response?.data && typeof e.response?.data === 'string' ? e.response?.data : e.message));
      if (response && response.status === 200) {
        const file = URL.createObjectURL(await response.data);
        const fileName = response.headers['content-disposition'].replace(/(^.+={1,1})|("+)/g, '');
        res({ file, fileName });
      }
    });
}
