import { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Typography, Grid, Button, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { DeleteUsersModal } from 'pages/admin/userDetails/deleteUserModal';
import { UserMainInfo } from 'pages/admin/userDetails/userMainInfo';
import { TabsCard } from 'pages/user/dashboard/tabs';
import { AddUserModal } from '../dashboard/addUser';
import { ButtonComponent } from 'components/ui';
import { EditIcon, CrossIcon } from 'components';

import { usePageTitle, useCurrentUnitsPerDollar } from 'helpers/hooks';
import { paths } from 'consts';
import { AdminApi } from 'api';

export const UserDetails = () => {
  const { palette } = useTheme();
  const adminId = useSelector((s) => s.userBaseInfo.userId);
  const { userId } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [waitEditUserData, setWaitEditUserData] = useState(false);
  const { currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading } = useCurrentUnitsPerDollar(userId);
  const navigate = useNavigate();
  usePageTitle('Merlin API Panel · API User Details');

  const allowToDelete = !!(userId && userId !== adminId);

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const toggleEditModal = () => setEditModal(!editModal);

  const onGoToUsers = () => navigate(`/${paths.admin.path}/${paths.users.path}`);

  const onRequestUserEditData = () => {
    setWaitEditUserData(true);
    AdminApi.getApiUserDetails(userId)
      .then((res) => {
        setEditUserData(res);
        toggleEditModal();
      })
      .finally(() => setWaitEditUserData(false));
  };

  return (
    <Fragment>
      <Card variant='outlined' sx={{ paddingY: 2, paddingX: 4, marginBottom: 3 }}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Button onClick={onGoToUsers} variant='outlined' sx={{ width: 32, height: 32, minWidth: 32, padding: 0, justifyContent: 'center' }}>
            <ArrowBackIcon fontSize='small' />
          </Button>
          <Typography variant='h4' sx={{ marginLeft: 2, marginRight: 'auto' }}>
            API User Details
          </Typography>

          {allowToDelete ? (
            <ButtonComponent onClick={toggleDeleteModal} variant='outlined' color='error' text='Delete' endIcon={<CrossIcon fontSize='small' size={14} />} height={32} width={95} />
          ) : null}
          <ButtonComponent
            marginLeft='20px'
            text='Edit User'
            onClick={onRequestUserEditData}
            variant='outlined'
            height={32}
            width={120}
            endIcon={<EditIcon size={14} color={waitEditUserData ? palette.text.secondary : palette.primary.main} />}
            disabled={waitEditUserData}
          />
        </Grid>
      </Card>
      <UserMainInfo {...{ currentUnitsPerDollar }} />
      <TabsCard {...{ currentUnitsPerDollar, currentUnitsPerDollarError, currentUnitsPerDollarLoading, userId, isAdminView: true }} />
      <AddUserModal {...{ open: editModal, onClose: toggleEditModal, updateUsersData: console.log, editUserData, key: 'edit-user-' + String(editModal) + userId }} />
      {allowToDelete ? <DeleteUsersModal {...{ open: deleteModal, onClose: toggleDeleteModal, userId, key: 'delete-user-' + String(deleteModal) }} /> : null}
    </Fragment>
  );
};
