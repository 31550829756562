import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Chart as ChartJS } from 'chart.js';
import { CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler } from 'chart.js';

import App from 'App';
import reportWebVitals from './reportWebVitals';
import { store } from 'store';
import 'index.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

const root = ReactDOM.createRoot(document.getElementById('root'));

const appColors = createTheme({
  palette: {
    primary: { main: '#5DC3F9', light: '#6bc5f5', dark: '#51bcf5', contrastText: '#fff' },
    secondary: { main: '#0E0E0E' },
    text: { primary: '#0E0E0E', secondary: '#99A4B8', dark: '#344767', light: '#fff', green: '#0CB213', red: '#DB5B54', linearGrey: '#7B809A' },
    error: { main: '#DB5B54' },
    background: { header: '#273445', content: '#FCFCFF' },
    plans: { FREE: '#eff2fd', BASE: '#5dc3f9', PRO: '#785cf9', PRO_PLUS: '#f2b054', ENTERPRISE: '#3d5abd' },
    portfolioStatus: { READY: '#0CB213', LOADING: '#297CF0' },
    icon: { main: '#fff' },
    border: { main: '#EFF2FD' },
    chart: { blue: '#5DC3F9', blueDark: '#3F8DD8', orange: '#F5AC37', pink: '#E84992', purple: '#785CF9' },
    greenBtn: { main: '#0CB213', light: '#15c91c', dark: '#049f0b', contrastText: '#fff' },
    redBtn: { main: '#DB5B54', light: '#d8635d', dark: '#db5049', contrastText: '#fff' },
    cancelBtn: { main: '#EFF2FD', dark: '#e7e8ef', contrastText: '#99A4B8' },
  },
});

const theme = createTheme({
  ...appColors,
  spacing: 6,
  typography: {
    allVariants: {
      fontFamily: ['"Outfit"', 'sans-serif'].join(','),
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      letterSpacing: 'normal',
    },
    h1: {
      fontSize: 42,
      fontWeight: 600,
      lineHeight: '53px',
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 'normal',
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '23px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    body1: { fontSize: 14, lineHeight: '18px', fontWeight: 400 },
    body2: { fontSize: 12, lineHeight: '18px', fontWeight: 400 },
    button: {
      fontSize: 12,
      justifyContent: 'space-between',
    },
    caption: {
      fontSize: 10,
      letterSpacing: 'normal',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: { borderColor: appColors.palette.border.main },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderColor: appColors.palette.border.main },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { boxShadow: 'none', '&:hover': { boxShadow: 'none' } },
        sizeSmall: { height: 30 },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: { borderColor: appColors.palette.border.main },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: { backgroundColor: appColors.palette.border.main },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 46,
        },

        sizeSmall: { height: 30 },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        input: { paddingTop: '13px', paddingBottom: '13px', paddingLeft: '16px', '&::placeholder': { color: appColors.palette.text.secondary, opacity: 1 } },
        notchedOutline: {
          borderColor: appColors.palette.border.main,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: appColors.palette.border.main,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: appColors.palette.primary.contrastText,
          border: `1px solid ${appColors.palette.border.main}`,
          color: appColors.palette.text.primary,
          boxShadow: appColors.shadows[1],
        },
        arrow: {
          '&:before': {
            border: `1px solid ${appColors.palette.border.main}`,
            boxShadow: appColors.shadows[1],
          },
          color: appColors.palette.primary.contrastText,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          width: 14,
          height: 14,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          width: 10,
          height: 10,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '13px',
          paddingBottom: '13px',
          paddingLeft: '16px',
        },
        icon: {
          color: '#5DC3F9',
          width: 18,
          height: 18,
          top: 15,
          right: 17,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: { fontSize: 14, fontWeight: 500 },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: appColors.palette.text.secondary,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: { borderColor: appColors.palette.text.secondary },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: { color: appColors.palette.primary.main },
      },
    },
  },
});

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
