import { logout } from 'store/slices/user';
import { resetBaseInfo } from 'store/slices/userBaseInfo';
import { resetRegistrationOptions } from 'store/slices/registrationOptions';
import { resetBuferSlice } from 'store/slices/bufer';
import { resetUserPrices } from 'store/slices/userPrices';
import { resetObserverSlice } from 'store/slices/updateObserver';
import { resetSubscriptionOptions } from 'store/slices/subscriptionOptions';
import { loginLocalStorageName } from 'consts';
import { store } from 'store';

export const resetStore = () => {
  const { dispatch } = store;
  dispatch(logout());
  dispatch(resetBaseInfo());
  dispatch(resetRegistrationOptions());
  dispatch(resetBuferSlice());
  dispatch(resetUserPrices());
  dispatch(resetObserverSlice());
  dispatch(resetSubscriptionOptions());
  localStorage.removeItem(loginLocalStorageName);
};
