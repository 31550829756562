import { useState, Fragment } from 'react';
import { Dialog, Alert, Grid, Typography, Card } from '@mui/material';

import { ButtonComponent } from 'components/ui';
import { AdminApi } from 'api';

export const DeletePromoCodeModal = ({ open, name = '', updateData = console.log, setRemoveName = console.log }) => {
  const [wait, setWait] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onCloseModal = (_, reason) => {
    if (reason === 'backdropClick' && wait) return;
    setRemoveName('');
  };

  const onDeletePromoCode = async () => {
    setWait(true);
    AdminApi.deletePromoCode(name)
      .then(() => {
        updateData();
        onCloseModal();
      })
      .catch((e) => {
        setErrorMessage(e);
        setWait(false);
      });
  };

  return (
    <Dialog open={open} onClose={onCloseModal} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Card sx={{ width: 320, padding: 4, paddingBottom: 5 }}>
        <Typography mb='12px' sx={{ marginBottom: 2 }} variant='h4'>
          Delete Promo Code
        </Typography>
        {errorMessage ? (
          <Alert severity='error'>{errorMessage}</Alert>
        ) : (
          <Fragment>
            <Typography mb='20px' variant='body2' component='p' color='text.secondary'>
              Are you sure to delete "{name}" Promo Code?
            </Typography>
            <Grid container justifyContent='space-between'>
              <ButtonComponent color='cancelBtn' disabled={wait} onClick={onCloseModal} text='CANCEL' width={76} />
              <ButtonComponent color='error' disabled={wait} onClick={onDeletePromoCode} text='DELETE' width={184} />
            </Grid>
          </Fragment>
        )}
      </Card>
    </Dialog>
  );
};
